import React, { useState, useRef, useEffect } from "react";
import { Button } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Fade } from "@mui/material";

import { CookiesProvider, useCookies } from "react-cookie";
import {
  GoogleLogin,
  GoogleOAuthProvider,
  useGoogleOneTapLogin,
  useGoogleLogin,
} from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";

import { PremiumStatusCtx } from "../../contexts";
import { createAccount, getAccount } from "../../apis/endpoints/Accounts";
import {
  getCredential,
  createLoginSession,
  isUserAuthenticated,
} from "../../utils/login";
import {
  isPremiumActive
} from "../../apis/endpoints/Payments";

const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;

export const GoogleLoginModal = ({ openModal, setOpenModal, callback }) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    textAlign: "center",
  };

  const [loginCookie, setLoginCookie] = useCookies(["currentUser"]);
  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);

  return (
    <Modal
      open={openModal}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      closeAfterTransition
      slotProps={{
        backdrop: {
          timeout: 500
        }
      }}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Fade in={openModal}>
        <Box
          sx={{
            padding: "24px",
            backgroundColor: "white",
            borderRadius: "16px",
            boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
            maxWidth: "400px",
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            id="modal-modal-title"
            variant="body1"
            component="h2"
            sx={{
              marginBottom: "20px",
              color: "#333",
              fontWeight: 'bold'
            }}
          >
            Create Your Account To Save Recordings!
          </Typography>
          <GoogleLoginButton callback={callback} />
        </Box>
      </Fade>
    </Modal>
  );
};

export const GoogleLoginButton = ({ callback }) => {
  // Default callback after successful login
  const [backdrop, setBackdrop] = React.useState(false);
  callback = callback ?? (() => {});

  const { isPremiumStatusActive, setPremiumStatus } = React.useContext(PremiumStatusCtx);
  const [loginCookie, setLoginCookie] = useCookies(["currentUser"]);
  const inputRef = React.useRef(null);
  const onSuccessLogin = (userCredential) => {
    const data = jwtDecode(userCredential.credential);

    // Extract user's data
    const userId = data.sub;
    const name = data.given_name;
    const email = data.email;
    const profile = data.picture;

    setBackdrop(true);
    createLoginSession(
      loginCookie,
      setLoginCookie,
      userId,
      name,
      email,
      profile
    )
      .then((credential) => {
        // Invoke any callback function after finishing creating login session
        callback(credential);

        isPremiumActive(credential.id, credential.email, credential.accessToken).then(result => {
          if (result.code == 200) {
            setPremiumStatus(result.data ?? false);
          }
        }).catch(err => {
          console.error("[GoogleLoginComponent.js] Failed to get premium status", err);
        });
      })
      .catch((error) => {
        console.error(
          "[GoogleLoginComponent.js] Failed to create login session",
          error
        );
      })
      .finally(() => {
        setBackdrop(false);
      });
  };

  const onErrorLogin = (error) => {
    console.log("Login Failed:", error);
  };

  return (
    <div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <GoogleLogin
        useOneTap={false}
        onSuccess={onSuccessLogin}
        onError={onErrorLogin}
      />
    </div>
  );
};

export const LogOutButton = ({ onClick }) => {
  return (
    <div>
      <Button color="primary" onClick={onClick}>
        <Typography variant="subtitle1" color="primary">
          Logout
        </Typography>
      </Button>
    </div>
  );
};
