import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { CookiesProvider, useCookies } from "react-cookie";
import $ from "jquery";
import { deleteNote, renameNote } from "../../apis/endpoints/Notes";
import { getCredential, isUserAuthenticated } from "../../utils/login";

import { FilterCtx } from "../../contexts";
const AppContext = React.createContext();

export default function NoteButtonGroup({
  notes,
  setNotes,
  note,
  loadNote,
  selectedNote,
  displayingConversations
}) {
  const options = ["Rename", "Delete"];
  const [cookies, setCookie] = useCookies(["currentUser"]);

  // Progress block screen
  const [backdrop, setBackdrop] = React.useState(false);

  const { isFilter, setFilter } = React.useContext(FilterCtx);
  const [isSelected, setIsSelected] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = React.useState(1);

  const handleClick = (e) => {
    if (selectedNote == null || selectedNote.id != note.id) {
      loadNote(note); // Load the conversations of the new note
    }
  };

  const handleMenuItemClick = (event, index) => {
    if (index == 0) {
      handleOpenRename(event);
    } else if (index == 1) {
      handleOpenDelete(event);
    }
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  // DELETE DIALOG
  const [openDelete, setOpenDelete] = React.useState(false);
  const handleOpenDelete = (e) => {
    e.stopPropagation();
    setOpenDelete(true);
  };
  const handleOkDelete = (e) => {
    e.stopPropagation();
    setOpenDelete(false);

    // Call API to delete the note, after that, load the new note list
    if (isUserAuthenticated(cookies)) {
      const cred = getCredential(cookies);

      setBackdrop(true);
      return deleteNote(cred.id, cred.email, cred.accessToken, note.id)
        .then((response) => {
          if (response.data != null) {
            return true;
          }
          return false;
        })
        .then((isDeleted) => {
          if (isDeleted) {
            // If filtering is on, remove all conversations from the filtered conversations that have id match the id of the conversations
            // in the removed note
            if (isFilter) {
              // Remove in-place
              let end = 0;
              for (let i = 0; i < displayingConversations.length; ++i) {
                const conv = displayingConversations[i];
                if (conv.noteId === note.id) {
                  displayingConversations[end++] = conv;
                }
              }
              displayingConversations.length = end;
            }

            // Remove the note & select another note to display if applied
            const filteredNotes = $.grep(notes, (e) => {
              return e.id != note.id;
            });
            setNotes(filteredNotes);
            loadNote((note.id == selectedNote.id) ? filteredNotes[0] ?? null : selectedNote);
          }
        })
        .catch((error) => {
          console.error(
            "[NoteButtonGroup.js] Failed to delete the selected note"
          );
        })
        .finally(() => {
          setBackdrop(false);
        });
    }
  };
  const handleCloseDelete = (e) => {
    e.stopPropagation();
    setOpenDelete(false);
  };

  // RENAME DIALOG
  const [openRename, setOpenRename] = React.useState(false);
  const handleOpenRename = (e) => {
    e.stopPropagation();
    setOpenRename(true);
  };
  const handleCloseRename = (e) => {
    e.stopPropagation();
    setOpenRename(false);
  };

  return (
    <React.Fragment>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <ButtonGroup
        id={note.id}
        className="is-light note-list-item is-fullwidth mb-2"
        variant="contained"
        ref={anchorRef}
        aria-label="Note button with option menu"
      >
        <Button className="main-button" onClick={handleClick}>
          {note.name}
        </Button>
        <Button
          size="small"
          aria-controls={open ? "split-button-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="menu"
          onClick={handleToggle}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 1,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {options.map((option, index) => (
                    <MenuItem
                      key={option}
                      onClick={(event) => handleMenuItemClick(event, index)}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>

      <Dialog
        open={openDelete}
        onClose={handleCloseDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{`Delete note?`}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {`Do you want to delete `}
            <b>{`${note.name}`}</b>
            {`?`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDelete}>Cancel</Button>
          <Button onClick={handleOkDelete} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openRename}
        onClose={handleCloseRename}
        PaperProps={{
          component: "form",
          onSubmit: (event) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries(formData.entries());
            const newName = formJson.name;
            handleCloseRename(event);

            if (isUserAuthenticated(cookies)) {
              setBackdrop(true);

              const cred = getCredential(cookies);
              renameNote(
                cred.id,
                cred.email,
                cred.accessToken,
                note.id,
                newName
              )
                .then((response) => {
                  if (response.data != null && response.data == true) {
                    // Update the note name display on client side
                    const renamedNote = notes.find((t) => t.id == note.id);
                    renamedNote.name = newName;
                    setNotes([...notes]);
                    loadNote(note);
                  }
                })
                .catch((error) => {
                  console.error(
                    "[NoteButtonGroup.js] Failed to rename the note",
                    error
                  );
                })
                .finally(() => {
                  setBackdrop(false);
                });
            }
          },
        }}
      >
        <DialogTitle>Rename</DialogTitle>
        <DialogContent>
          <DialogContentText>Enter a new name for this note:</DialogContentText>
          <TextField
            required
            margin="dense"
            id="name"
            name="name"
            label="Name"
            type="text"
            fullWidth
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseRename}>Cancel</Button>
          <Button type="submit">Rename</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
