import React, {
  useEffect,
  useRef,
  useState,
  useContext,
  createContext,
} from "react";

import Button from "@mui/material/Button";

import Conversation from "./Conversation";
import "./NoteBox.css";

import {
  getSingleConversationFromNote,
  countConversationFromNote,
  getConversationsFromNote,
  getRawConversation,
} from "../apis/endpoints/Conversation";

import {
  parseConversation,
  createPendingConversation,
} from "../utils/audioConversations";

import { CookiesProvider, useCookies } from "react-cookie";
import { getCredential, isUserAuthenticated } from "../utils/login";

import { FilterCtx } from "../contexts";
const AppContext = React.createContext();

function NoteBox({ notes, selectedNote, displayedMatches, loadNote }) {
  const [cookies, setCookie] = useCookies(["currentUser"]);
  const [loadingOldConversations, setLoadingOldConversations] = useState(false);

  const { isFilter, setFilter } = useContext(FilterCtx);
  const endOfConversationsRef = useRef(null);
  const conversationsRef = useRef(null);
  const currentNoteConversations =
    selectedNote == null || selectedNote.conversations == null
      ? []
  : selectedNote.conversations;

  const scrollToBottom = () => {
    if (conversationsRef.current) {
      conversationsRef.current.scrollTop =
        conversationsRef.current.scrollHeight;
    }
  };

  // This code section handle the logic of loading old conversations when user
  // scroll to the top of the note conversation list
  const handleScroll = (event) => {
    const currentOffset = event.target.scrollTop;
    const scrollHeight = event.target.scrollHeight;
    if (currentOffset <= 0.0 * scrollHeight) {
      // want to add a check to see if there are older convos, otherwise don't update. 
      setLoadingOldConversations(true);
      event.target.scrollTo({
        top: 20, // Adjust this value to scroll slightly down
        behavior: "smooth" // This creates the smooth scrolling effect
      });

      setTimeout(() => { // 5 second delay 
      }, 5000);
    }
  };

  useEffect(() => {
    // Call API to load older conversations if the state is set to true,
    // when the API finishes, set loadingOldConversations to false
    // loadingOldConversations makes sure that while this variable is true, a call to API
    // to load historical conversations is being processed, so no other new API call should be
    // make
    if (loadingOldConversations && selectedNote != null) {
      if (isUserAuthenticated(cookies)) {
        const cred = getCredential(cookies);

        // Get number of conversations in the selected note to check if need to call API to retrieve more notes. This is to handle
        // the creating pending conversation logic, pending conversation will create a skeleton component in the conversation note even if we
        // have already loaded all conversations of the note
        countConversationFromNote(
          cred.id,
          cred.email,
          cred.accessToken,
          selectedNote.id
        )
          .then((response) => {
            if (
              response.data != null &&
              selectedNote.conversations.length < response.data
            ) {
              // Get number of conversations are in the list to get the query offset value
              const offset = selectedNote.conversations.length;
              const pendingConversation = createPendingConversation(
                getSingleConversationFromNote(
                  cred.id,
                  cred.email,
                  cred.accessToken,
                  selectedNote.id,
                  offset,
                  null,
                  null,
                  ["id", "audioFileName", "content", "timestamp", "name"]
                )
              );
              if (conversationsRef.current)
                conversationsRef.current.scrollBy(0, 20); // Scroll down a little to create animation
              selectedNote.conversations.unshift(pendingConversation);
            }
          })
          .catch((error) => {
            console.error(
              "[ConversationBox.js] Failed to get the number of conversations in the note",
              error
            );
          })
          .finally(() => {
            setLoadingOldConversations(false);
          });
      } else {
        setLoadingOldConversations(false);
      }
    }
  }, [loadingOldConversations]);

  useEffect(()=>{
    setFilter(false);
  },[selectedNote]);
  useEffect(()=>{
    setFilter(true);
  },[displayedMatches]);

  return (
    <div className="conversation-box">
      <div
        className="note-conversations"
        onScroll={handleScroll}
        ref={conversationsRef}
      >
        {((conversations) =>
          conversations.map((conversation) => {
            return (
              <Conversation
              // key={crypto.randomUUID()}
              key={conversation.id || crypto.randomUUID()}
              allNotes={notes}
              conversation={conversation}
              conversations={conversations}
              reloadSelectedNote={() => loadNote(selectedNote)}
              scrollToBottom={scrollToBottom}
              />
            )
          }))(isFilter ? displayedMatches : currentNoteConversations)}
        <div ref={endOfConversationsRef} /> {/* Scroll target */}
      </div>
    </div>
  );
}
export default NoteBox;
 