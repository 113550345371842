import {
  CREATE_AUDIO_NOTE_ENDPOINT,
  CREATE_TEXT_NOTE_ENDPOINT,
  DELETE_NOTE_ENDPOINT,
  GET_SINGLE_NOTE_FROM_CHAT_ENDPOINT,
  GET_NOTES_FROM_CHAT_ENPOINT,
  GET_AUDIO_SIGNED_URL_ENDPOINT,
  GET_PARSED_NOTE_ENDPOINT,
  GET_RAW_NOTE_ENDPOINT,
  GET_NOTE_COUNT_ENDPOINT,
} from "../utils/constants.js";
import { makeRequest, generateAuthHeader } from "../utils/utils.js";
import FormData from "form-data";

/**
 * Call API to upload create a text conversations
 * @param {string} userId The unique identifier for the user
 * @param {string} email The token used for authentication
 * @param {string} accessToken The email address associated with the user
 * @param {string} conversation The text conversation from user
 * @param {string} noteId The unique identifier of the note associated with this conversation
 * @return
 */
export const createTextConversation = async (
  userId,
  email,
  accessToken,
  conversation,
  noteId
) => {
  return makeRequest(
    CREATE_TEXT_NOTE_ENDPOINT,
    generateAuthHeader(userId, email, accessToken),
    {
      content: conversation,
      postId: noteId,
    }
  );
};

/**
 * Call API to upload recorded audio to the server for processing
 * @param {string} userId The unique identifier for the user
 * @param {string} email The token used for authentication
 * @param {string} accessToken The email address associated with the user
 * @param {} audioFile The recorded audio file content to be uploaded
 * @param {string} noteId The unique identifier of the note associated with this conversation
 * @param {string} tag An identifier tag to specify the type of this note e.g. todolist, email, summary. If a null value is passed in, the API will summarize the text
 * @return
 */
export const createAudioConversation = async (
  userId,
  email,
  accessToken,
  audioFile,
  noteId,
  tag
) => {
  const formData = new FormData();
  formData.append("content", audioFile);
  formData.append("postId", noteId);
  formData.append("tag", tag);

  return makeRequest(
    CREATE_AUDIO_NOTE_ENDPOINT,
    {
      ...generateAuthHeader(userId, email, accessToken),
      "content-type": "multipart/form-data",
    },
    formData
  );
};

/**
 * Call API to delete a note (audio file and text) from the database and the storage using its unique identifier
 * @param {string} userId The unique identifier for the user
 * @param {string} email The token used for authentication
 * @param {string} accessToken The email address associated with the user
 * @param {string} noteId 	The unique identifier of the note to be deleted
 * @return
 */
export const deleteNote = async (userId, email, accessToken, noteId) => {
  return makeRequest(
    DELETE_NOTE_ENDPOINT,
    generateAuthHeader(userId, email, accessToken),
    {
      noteId: noteId,
    }
  );
};

/**
 * Call API to get multiple parsed JSON objects of the conversations
 * @param {string} userId The unique identifier for the user
 * @param {string} email The token used for authentication
 * @param {string} accessToken The email address associated with the user
 * @param {string} noteId The unique identifier of the note being queried from
 * @param {number} limit The maximum number of conversations to return from the note
 * @param {number} offset The position in the note from which to begin returning conversations
 * @param {number} [start=null] The UNIX timestamp in seconds specifying the start date of the search range
 * @param {number} [end=null] The UNIX timestamp in seconds specifying the end date of the search range
 * @param {string[]} [parameters=null] An array of fields in a note object that should be returned (e.g., id, content, parsedContent).
 * @return
 */
export const getConversationsFromNote = async (
  userId,
  email,
  accessToken,
  noteId,
  limit,
  offset,
  start = null,
  end = null,
  parameters = null
) => {
  return makeRequest(
    GET_NOTES_FROM_CHAT_ENPOINT,
    generateAuthHeader(userId, email, accessToken),
    {
      postId: noteId,
      limit: limit,
      offset: offset,
      start: start,
      end: end,
      parameters: parameters.join(","),
    }
  );
};

/**
 * Call API to get the signed URL to download audio file of the selected note using the file name or note id
 * @param {string} userId The unique identifier for the user
 * @param {string} email The token used for authentication
 * @param {string} accessToken The email address associated with the user
 * @param {string} key The identifier to be used based on parameter type
 * @param {string} type Indicates how to interpret the key parameter. Possible values: noteId (to query the database for the filename) or audioFileName (to access the cloud directly)
 * @return
 */
export const getNoteAudioUrl = async (
  userId,
  email,
  accessToken,
  key,
  type
) => {
  return makeRequest(
    GET_AUDIO_SIGNED_URL_ENDPOINT,
    generateAuthHeader(userId, email, accessToken),
    {
      key: key,
      type: type,
    }
  );
};

/**
 * Call API to get the parsed JSON object of the note
 * @param {string} userId The unique identifier for the user
 * @param {string} email The token used for authentication
 * @param {string} accessToken The email address associated with the user
 * @param {string} noteId unique identifier of the note
 * @return
 */
export const getJsonNote = async (userId, email, accessToken, noteId) => {
  return makeRequest(
    GET_PARSED_NOTE_ENDPOINT,
    generateAuthHeader(userId, email, accessToken),
    {
      noteId: noteId,
    }
  );
};

/**
 * Call API to get the text content of the conversation or the transcribed text if the conversation is audio
 * @param {string} userId The unique identifier for the user
 * @param {string} email The token used for authentication
 * @param {string} accessToken The email address associated with the user
 * @param {string} conversationId unique identifier of the note
 * @return
 */
export const getRawConversation = async (
  userId,
  email,
  accessToken,
  conversationId
) => {
  return makeRequest(
    GET_RAW_NOTE_ENDPOINT,
    generateAuthHeader(userId, email, accessToken),
    {
      noteId: conversationId,
    }
  );
};

/**
 * Call API to get a single parsed JSON object of the conversation
 * @param {string} userId The unique identifier for the user
 * @param {string} email The token used for authentication
 * @param {string} accessToken The email address associated with the user
 * @param {string} noteId The unique identifier of the note being queried from
 * @param {number} offset The position in the note from which to begin returning conversations
 * @param {number} [start=null] The UNIX timestamp in seconds specifying the start date of the search range
 * @param {number} [end=null] The UNIX timestamp in seconds specifying the end date of the search range
 * @param {string[]} [parameters=null] An array of fields in a note object that should be returned (e.g., id, content, parsedContent).
 * @return
 */
export const getSingleConversationFromNote = async (
  userId,
  email,
  accessToken,
  noteId,
  offset,
  start = null,
  end = null,
  parameters = null
) => {
  return makeRequest(
    GET_SINGLE_NOTE_FROM_CHAT_ENDPOINT,
    generateAuthHeader(userId, email, accessToken),
    {
      postId: noteId,
      offset: offset,
      start: start,
      end: end,
      parameters: parameters.join(","),
    }
  );
};

/**
 * returns total number of conversations in a note, if note id is null, count all conversations that user has
 * @param {string} userId The unique identifier for the user
 * @param {string} email The token used for authentication
 * @param {string} accessToken The email address associated with the user
 * @param {string} noteId The unique identifier of the note being queried from
 * @return
 */
export const countConversationFromNote = async (
  userId,
  email,
  accessToken,
  noteId = null
) => {
  return makeRequest(
    GET_NOTE_COUNT_ENDPOINT,
    generateAuthHeader(userId, email, accessToken),
    {
      postId: noteId,
    }
  );
};
