import React, { useEffect, useRef, useState, useContext } from "react";
import {
  Box,
  Typography,
  Divider,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Chip,
} from "@mui/material";
import { common } from "@mui/material/colors";
import { countConversationFromNote } from "../apis/endpoints/Conversation";

import { FilterCtx } from "../contexts";
const AppContext = React.createContext();

const keywordQuery = (keyword, conversationsArray) => {
  // Convert keyword to lowercase
  const lowerKeyword = keyword.toLowerCase();
  const matchesList = [];
  // console.log("keywordQuery:", keyword, conversationsArray);

  if (conversationsArray == null) {
    return matchesList;
  }
  // Iterate over each conversation in the array
  conversationsArray.forEach((conversation) => {
    if (conversation) {
      const { id, name, text } = conversation;

      // Convert conversation name and text to lowercase for comparison
      const lowerName = (conversation.name ?? "").toLowerCase();
      const lowerRawText = (conversation.text ?? "").toLowerCase();
      const lowerParsedText = JSON.stringify((conversation.content ?? {})).toLowerCase();

      // Check if the keyword exists in the name or text of the main conversation
      if (
        lowerName.includes(lowerKeyword) ||
        lowerRawText.includes(lowerKeyword) ||
        lowerParsedText.includes(lowerKeyword)
      ) {
        matchesList.push(conversation); // Add the conversation's to the list if it matches
      }
    } else {
      console.error("The object is undefined or null");
    }

    // // Check if there are subConversations and search within them
    // if (subConversations.length > 0) {
    //   subConversations.forEach((subConversation) => {
    //     const { name: subname, text: subtext } = subConversation;

    //     // Convert subConversation name and text to lowercase for comparison
    //     const lowerSubname = subname.toLowerCase();
    //     const lowerSubtext = subtext.toLowerCase();

    //     // If the keyword matches in subConversations, add the parent conversation's id
    //     if (
    //       lowerSubname.includes(lowerKeyword) ||
    //       lowerSubtext.includes(lowerKeyword)
    //     ) {
    //       if (!matchesIdList.includes(id)) {
    //         matchesIdList.push(id); // Avoid duplicates in the matchesIdList
    //       }
    //     }
    //   });
    // }
  });
  // console.log("id list match", matchesIdList);
  // console.log("keyword Query", keyword, matchesList);

  return matchesList;
};

const chipQuery = (selectedLabels, conversationsArray) => {
  // Helper function to check if any label in a conversation matches selectedLabels
  const hasMatchingLabel = (labels) =>
    labels.some((label) => selectedLabels.includes(label));

  // Filter conversations based on whether their labels match selectedLabels
  return conversationsArray
    .filter(
      (conversation) =>
        hasMatchingLabel(conversation.labels) ||
        conversation.subConversations.some((subConversation) =>
          hasMatchingLabel(subConversation.labels)
        )
      // right now the object conversation has attribute subConversations
      // will update later***
    )
    .map((conversation) => conversation.id);
};

function ConversationFilter({
  labels,
  selectedNote,
  allNotes,
  setDisplayedMatches,
  loadAllConversations,
}) {
  const { isFilter, setFilter } = useContext(FilterCtx);

  // given an array of allNotes, create an array called conversations
  // Function to retrieve conversations for all notes with null conversations

  // TODO: The search & filter should be handled by an API call to the backend
  // to operate on the database instead of in the client. Right now, this feature only
  // search on the 10 conversations from each note, retrieving all conversations in all notes
  // will be expensive

  // Call the function to load conversations
  loadAllConversations();
  const [allConversationMatches, setAllConversationMatches] = useState([]);

  function mergeAllConversations(allNotes) {
    // Create an empty array to hold all conversations
    let allConversations = [];

    // Iterate through each note in allNotes
    allNotes.forEach((note) => {
      // Merge each note's conversations into allConversations
      allConversations = allConversations.concat(note.conversations);
    });
    return allConversations;
  }

  const [keywordQueryMatches, setKeywordQueryMatches] = useState([]);
  const [labelQueryMatches, setLabelQueryMatches] = useState([]);
  const [dateQueryMatches, setdateQueryMatches] = useState([]);
  // State to keep track of selected labels
  const [selectedLabels, setSelectedLabels] = useState(new Set());

  function updateDisplayedMatches() {
    // Find the common IDs present in all three arrays
    const commonMatches = keywordQueryMatches;

    // Call setDisplayedMatches with the new array of common matches
    setDisplayedMatches(commonMatches);
    setAllConversationMatches(mergeAllConversations(allNotes));
  }
  // Use useEffect to call updateDisplayedMatches whenever any of the dependencies change
  useEffect(() => {
    // Only update the list of matching conversations while filtering is active
    isFilter && updateDisplayedMatches();
  }, [keywordQueryMatches]);

  const handleKeywordChange = (event) => {
    const keyword = event.target.value.trim();
    if (keyword === "") {
      // If no keyword is passed into the filter, display the list of conversations in the currently selected
      // note
      setFilter(false);
      setKeywordQueryMatches((selectedNote == null) ? [] : selectedNote.conversations);
    } else {
      setFilter(true);
      setKeywordQueryMatches(keywordQuery(keyword, allConversationMatches));
    }
  };
  // implement later
  // const handleChipClick = (label) => {
  //   setSelectedLabels((prevSelected) => {
  //     const updated = new Set(prevSelected);
  //     if (updated.has(label)) {
  //       updated.delete(label);
  //     } else {
  //       updated.add(label);
  //     }
  //     // Convert the Set to an array before calling chipQuery
  //     const selectedLabelsArray = Array.from(updated);
  //     setLabelQueryMatches(chipQuery(selectedLabelsArray, conversations));
  //     return updated;
  //   });
  // };
  // // whenever selectedLabel changes lets do something
  // useEffect(() => {
  //   if (selectedLabels.size === 0) {
  //     setLabelQueryMatches((conversations));
  //   }
  // }, [selectedLabels]);
  return (
    <div>
      <Typography variant="h6" gutterBottom>
        Filters
      </Typography>
      <TextField
        label="Search"
        variant="outlined"
        fullWidth
        sx={{ marginBottom: "1rem" }}
        onChange={handleKeywordChange} // Update the state on change
      />
      {
        // <FormControl fullWidth sx={{ marginBottom: "1rem" }}>
        //   <InputLabel>Date Range</InputLabel>
        //   <Select label="Date Range" defaultValue="">
        //     <MenuItem value="last7days">Last 7 days</MenuItem>
        //     <MenuItem value="last30days">Last 30 days</MenuItem>
        //     <MenuItem value="all">All time</MenuItem>
        //   </Select>
        // </FormControl>
      }
      {
        // <Typography variant="subtitle1" gutterBottom>
        //   Labels
        // </Typography>
        // <Box sx={{ display: "flex", flexWrap: "wrap", gap: "0.5rem", marginBottom: "1rem" }}>
        //   {labels.map((label) => (
        //     <Chip
        //       key={label}
        //       label={label}
        //       clickable
        //       onClick={() => handleChipClick(label)}
        //       sx={{
        //         backgroundColor: selectedLabels.has(label)
        //           ? "primary.main"
        //           : "default",
        //         color: selectedLabels.has(label) ? "white" : "default",
        //       }}
        //     />
        //   ))}
        // </Box>
      }
    </div>
  );
}
export default ConversationFilter;
