import React, { useEffect, useRef, useState, useContext } from "react";
import { Box, Menu, MenuItem, LinearProgress } from "@mui/material";
import Grid from "@mui/material/Grid2";
import Skeleton from "@mui/material/Skeleton";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CommentIcon from "@mui/icons-material/Comment";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import OutboundIcon from "@mui/icons-material/Outbound";
import Forward10Icon from "@mui/icons-material/Forward10";
import Replay10Icon from "@mui/icons-material/Replay10";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import { CookiesProvider, useCookies } from "react-cookie";
import {
  getJsonNote,
  getNoteAudioUrl,
  deleteNote,
} from "../apis/endpoints/Conversation";
import { getCredential, isUserAuthenticated } from "../utils/login";
import {
  updatePendingConversation,
  downloadAudio,
} from "../utils/audioConversations";

import axios from "axios";
import { FilterCtx } from "../contexts";
const AppContext = React.createContext();

import SummaryContent from "./conversations/main/summary";
import TodoListContent from "./conversations/main/todolist";

function Conversation({ allNotes, conversation, conversations, reloadSelectedNote, scrollToBottom }) {
  // Reference to the audio element
  const [cookies, setCookie] = useCookies(["currentUser"]);
  const { isFilter, setFilter } = useContext(FilterCtx);
  const [currentConversation, setConversation] = useState(conversation);
  const [anchorEl, setAnchorEl] = useState(null);
  const [shareAnchorEl, setShareAnchorEl] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [backdrop, setBackdrop] = React.useState(false);
  const audioRef = useRef(null);

  useEffect(() => {
    const loadData = async () => {
      if (isUserAuthenticated(cookies)) {
        const cred = getCredential(cookies);
        if (currentConversation.hasOwnProperty("promise")) {
          // Promise attached to the conversation, wait until promise returned & set pending to false to
          // hide the spinner animation
          const conversationResponse = await currentConversation.promise.catch(
            (error) => {
              console.error(
                "[Conversation.js] Failed to load content of old conversation",
                error
              );
              return {};
            }
          );

          if (conversationResponse.code == 200 && conversationResponse.data) {
            // Update the currentConversation object and delete the promise to signal that this conversation API call has
            // been fulfilled. Do not create a new conversation object because currentConversation is referencing to the
            // object stored in notes
            updatePendingConversation(
              currentConversation,
              conversationResponse.data
            );

            // Load JSON content of the conversation
            const jsonContentResponse = await getJsonNote(
              cred.id,
              cred.email,
              cred.accessToken,
              currentConversation.id
            ).catch((error) => {
              console.error(
                "[Conversation.js] Failed to load JSON content of old conversation",
                error
              );
              return {};
            });
            if (jsonContentResponse.code == 200 && jsonContentResponse.data) {
              currentConversation.content = jsonContentResponse.data;
            }

            // Get signed url for audio file of this conversation
            const audioResponse = await downloadAudio(
              cred.id,
              cred.email,
              cred.accessToken,
              currentConversation.id
            ).catch((error) => {
              console.error(
                "[Conversation.js] Failed to download the audio file",
                error
              );
              return null;
            });
            if (audioResponse != null) {
              currentConversation.url = audioResponse;
            }

            // If this is the final conversation in the selected note list, scroll to the bottom
            setConversation({ ...currentConversation });
            if (
              conversations.length &&
              conversations.at(-1).id == currentConversation.id
            ) {
              // scrollToBottom();
            }
          }
        } else {
          // Load JSON content of the conversation
          const jsonContentResponse = await getJsonNote(
            cred.id,
            cred.email,
            cred.accessToken,
            currentConversation.id
          ).catch((error) => {
            console.error(
              "[Conversation.js] Failed to load JSON content of old conversation",
              error
            );
            return {};
          });
          if (jsonContentResponse.code == 200 && jsonContentResponse.data) {
            currentConversation.content = jsonContentResponse.data;
          }

          // Get signed URL of the audio conversation if applied
          if (currentConversation.hasAudio && currentConversation.url == null) {
            const audioUrl = await downloadAudio(
              cred.id,
              cred.email,
              cred.accessToken,
              currentConversation.id
            ).catch((error) => {
              console.error(
                "[Conversation.js] Failed to download the audio file",
                error
              );
              return null;
            });
            if (audioUrl != null) {
              currentConversation.url = audioUrl;
            }
          }
          setConversation({ ...currentConversation });
        }
      }
    };
    const loadMessagesAndScroll = async () => {
      await loadData(); // Wait for loadData to complete
      scrollToBottom(); // Scroll to bottom after data is loaded

    };

    loadMessagesAndScroll();

    // loadData();
  }, []);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleEdit = () => {
    setIsEditing(true);
    handleMenuClose();
  };

  const handleSave = () => {
    message.name = editedTitle;
    message.text = editedSummary;
    setIsEditing(false);
  };

  const handleCancel = () => {
    setEditedTitle(message.name);
    setEditedSummary(message.text);
    setIsEditing(false);
  };

  // DELETE CONVERSATION DIALOG
  const [openDelete, setOpenDelete] = useState(false);
  const handleDelete = (e) => {
    e.stopPropagation();
    setBackdrop(true);
    setOpenDelete(false);

    const cred = getCredential(cookies);
    deleteNote(cred.id, cred.email, cred.accessToken, currentConversation.id)
      .then((result) => {
        if (result.code == 200) {
          currentConversation.audioTag = "undefined"; // ?

          // Remove the conversation from the list displaying it
          const idx = conversations.findIndex(
            (c) => c.id == currentConversation.id
          );
          conversations.splice(idx, 1);

          if (isFilter) {
            // If is filter, besides removing from the list of currently display conversations, need to lookup
            // in which note was the removed conversation from & delete it from that note
            // currentConversation.noteId
            const targetNoteIdx = allNotes.findIndex(
              (n) => n.id == currentConversation.noteId
            );
            const noteConvIdx = allNotes[targetNoteIdx].conversations.findIndex(
              (c) => c.id == currentConversation.id
            );
            allNotes[targetNoteIdx].conversations.splice(noteConvIdx, 1);
          }

          // This helps trigger the UI update
          reloadSelectedNote();
        }
      })
      .catch((error) => {
        console.error(
          "[Conversation.js] Failed to delete the selected conversation"
        );
      })
      .finally(() => {
        setBackdrop(false);
      });
  };
  const handleOpenDelete = () => {
    setOpenDelete(true);
    handleMenuClose();
  };
  const handleCloseDelete = (e) => {
    e.stopPropagation();
    setOpenDelete(false);
  };

  const handleAddComment = () => {
    setIsEditing(true);
  };

  const handleShareClick = (event) => {
    setShareAnchorEl(event.currentTarget);
  };

  const handleShareClose = () => {
    setShareAnchorEl(null);
  };

  const handleShareThread = () => {
    copyToClipboard(`${mainUrl}?noteId=${message.id}`);
  };

  const handleShareSubnote = (subNoteId) => {
    copyToClipboard(`${mainUrl}?noteId=${message.id}&subnoteId=${subNoteId}`);
  };

  const setAudioTag = (tag) => {
    if (tag == "summary") {
      currentConversation.audioTag = "Summary";
    } else if (tag == "todolist") {
      currentConversation.audioTag = "To-Do List";
    } else if (tag == "blog") {
      currentConversation.audioTag = "Blog";
    } else if (tag == "email") {
      currentConversation.audioTag = "Email";
    }
  };

  // FUNCTIONS RELATED TO CONVERSATION AUDIO PLAYBACK
  // Function to fast forward by 10 seconds
  const fastForward = (e) => {
    e.stopPropagation();
    if (audioRef.current) {
      audioRef.current.currentTime += 10; // Fast forward 10 seconds
    }
  };
  // Function to backtrack by 10 seconds
  const backtrack = (e) => {
    e.stopPropagation();
    if (audioRef.current) {
      audioRef.current.currentTime -= 10; // Backtrack 10 seconds
    }
  };

  // FUNCTIONS RELATED TO FEEDBACK FORM
  const formLink = () => {
    // Function to open feedback form in a new tab
    const feedbackFormUrl = "https://app.youform.com/forms/h45cwxbu"; // Replace with your actual form URL
    window.open(feedbackFormUrl, "_blank", "noopener,noreferrer");
  };

  return (
    <div>
      {/* Summary Message */}
      <Card
        variant="outlined"
        sx={{ mb: 2, boxShadow: 3, position: "relative" }}
      >
        <CardContent>
          {/* Setting position to absolute & parent position to relative to make this backdrop cover only the component*/}
          <Backdrop
            sx={{
              color: "#fff",
              zIndex: (theme) => theme.zIndex.drawer + 1,
              position: "absolute",
            }}
            open={backdrop}
          >
            <CircularProgress color="inherit" />
          </Backdrop>

          {currentConversation.hasOwnProperty("promise") ? (
            <Box sx={{ width: "100%" }}>
              <Skeleton />
              <Skeleton animation="wave" />
              <Skeleton animation={false} />
            </Box>
          ) : (
            <div>
              <Grid container spacing={2}>
                <Grid size={10}>
                  <Typography
                    variant="h6"
                    component="div"
                    gutterBottom
                    fontWeight="bold"
                  >
                    {`${currentConversation.name ?? "Conversation"}`}
                  </Typography>
                </Grid>
                <Grid size={2}>
                  <Box
                    sx={{ display: "flex", justifyContent: "flex-end", mb: 1 }}
                  >
                    {
                      // <IconButton
                      //   sx={{
                      //     color: '#008080',
                      //   }}
                      //   onClick={handleAddComment}
                      // >
                      //   <CommentIcon />
                      // </IconButton>
                      //
                      // <IconButton
                      //   sx={{
                      //     color: '#008080',
                      //   }}
                      //   onClick={handleShareClick}
                      // >
                      //   <OutboundIcon />
                      // </IconButton>
                      // <Menu
                      //   anchorEl={shareAnchorEl}
                      //   open={Boolean(shareAnchorEl)}
                      //   onClose={handleShareClose}
                      // >
                      //   <MenuItem onClick={handleShareThread}>
                      //     Share entire thread
                      //   </MenuItem>
                      //   <MenuItem onClick={() => handleShareSubnote(message.id)}>
                      //     Share subnote
                      //   </MenuItem>
                      // </Menu>
                    }

                    <IconButton onClick={handleMenuClick}>
                      {!isEditing && <MoreVertIcon />}
                    </IconButton>
                    <Menu
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      onClose={handleMenuClose}
                    >
                      {
                        // <MenuItem onClick={handleEdit}>
                        //   <EditIcon sx={{ marginRight: '8px' }} /> Edit
                        // </MenuItem>
                      }
                      <MenuItem onClick={handleOpenDelete}>
                        <DeleteIcon sx={{ marginRight: "8px" }} /> Delete
                      </MenuItem>
                    </Menu>
                  </Box>
                </Grid>
              </Grid>

              {isEditing && (
                <div>
                  <TextField
                    fullWidth
                    label="Title"
                    variant="outlined"
                    value={editedTitle}
                    onChange={(e) => setEditedTitle(e.target.value)}
                    sx={{ marginBottom: "1rem" }}
                  />
                  <ReactQuill
                    value={editedSummary}
                    onChange={setEditedSummary}
                    style={{ marginBottom: "1rem" }}
                  />
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleSave}
                    >
                      Save
                    </Button>
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={handleCancel}
                    >
                      Cancel
                    </Button>
                  </Box>
                </div>
              )}

              {(() => {
                switch ((currentConversation.content ?? {}).type) {
                  case 'summary':
                    return <SummaryContent object={currentConversation.content ?? {}}/>
                  case 'todolist':
                    return <TodoListContent object ={currentConversation.content ?? {}}/>
                  default:
                    return <div></div>;
                }
              })()}

              {currentConversation.hasAudio && currentConversation.url && (
                <Accordion
                  sx={{
                    "&:before": { display: "none" },
                    boxShadow: "none",
                    border: "solid 0px",
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    <audio controls ref={audioRef} style={{ width: "100%" }}>
                      <source src={currentConversation.url} type="audio/mp3" />
                      Your browser does not support the audio element.
                    </audio>
                    <Box sx={{ mt: 1 }}>
                      <Box sx={{ display: "flex", justifyContent: "center" }}>
                        <IconButton
                          onClick={backtrack}
                          color="primary"
                          size="small"
                          sx={{ mr: 1 }}
                        >
                          <Replay10Icon />
                        </IconButton>
                        <IconButton
                          onClick={fastForward}
                          color="primary"
                          size="small"
                        >
                          <Forward10Icon />
                        </IconButton>
                      </Box>
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails sx={{ fontSize: "default" }}>
                    {currentConversation.text}
                  </AccordionDetails>
                </Accordion>
              )}
              <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
                <Button variant="contained" onClick={formLink}>
                  Feedback Form
                </Button>
              </Box>
            </div>
          )}
        </CardContent>
      </Card>

      <Dialog
        open={openDelete}
        onClose={handleCloseDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{`Delete conversation?`}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {`Do you want to delete this conversation?`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDelete}>Cancel</Button>
          <Button onClick={handleDelete} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
export default Conversation;
