import React from "react";
import { GoogleLogin, GoogleOAuthProvider, useGoogleLogin } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";
import { useJwt } from "react-jwt";

import {createAccount, getAccount} from '../apis/endpoints/Accounts';

/**
* Get the credential data for API authentication of the current user
* @param {object} cookies An object contains all key/value pairs cookie data of the current site
* @return
*/
export const getCredential = (cookies) => {
  let credential = null;
  if (isUserAuthenticated(cookies)) {
    credential = {
      id: cookies.currentUser.id,
      email: cookies.currentUser.email,
      accessToken: cookies.currentUser.accessToken,
    };
  }
  return credential;
}

export const isUserAuthenticated = (cookies) => {
  return cookies.currentUser ?? false
}

export const createLoginSession = (cookie, setCookie, userId, name, email, profile) => {
  const parameters = ["accessToken"];
  const setLoginCookie = (accessToken) => {
    const credential = {
      id: userId,
      email: email,
      accessToken: accessToken,
    };

    setCookie("currentUser", {
      ...credential,
      name: name,
      profile: profile,
    }, {
      path: "/",
      maxAge: 15552000 // Login session is valid for 180 days
    });

    return credential;
  }

  let credential = null;
  const allPromises = [];
  return getAccount(userId, parameters).then(response => {
    if (response.data != null) {
      return setLoginCookie(response.data.accessToken);
    } else {
      // Create account & call API again to get access credentials
      return createAccount(userId, email, name, profile).then(response => {
        return response.data == true // response.data can return 2 types boolean | null
      }).then(isAccountCreated => {
        if (isAccountCreated) {
          return getAccount(userId, parameters).then(response => {
            if (response.data != null) {
              return setLoginCookie(response.data.accessToken);
            }
          }).catch(error => {
            console.error("[login.js] Failed to retrieve account", error);
            return null;
          });
        }
        return null;
      }).catch(error => {
        console.error("[login.js] Failed to create account", error);
        return null;
      });
    }
  }).catch(error => {
    console.error("[login.js] Failed to retrieve account", error);
    return null;
  });
}

export const logOutUser = (cookie, setCookie) => {
  setCookie("currentUser", null);
}
