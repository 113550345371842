import axios from "axios";

/**
* Generate REST API header that contains authentication parameters
* @param {userId}
* @param {email}
* @param {accessToken}
* @param {kwargs}
*/
export const generateAuthHeader = (userId, email, accessToken, kwargs = {}) => {
  return {
    "user-id": userId,
    "email": email,
    "access-token": accessToken,
    ...kwargs
  };
}

const get = async (endpoint, headers, data) => {
  return axios({
    ...endpoint,
    headers: headers,
    params: data
  }).then(response => response.data);
};

const post = async (endpoint, headers, data) => {
  return axios({
    ...endpoint,
    headers: headers,
    data: data
  }).then(response => response.data);
};

const put = async (endpoint, headers, data) => {
  return axios({
    ...endpoint,
    headers: headers,
    data: data
  }).then(response => response.data);
};

const _delete = async (endpoint, headers, data) => {
  return axios({
    ...endpoint,
    headers: headers,
    data: data
  }).then(response => response.data);
};

export const makeRequest = async (endpoint, headers, data = {}) => {
  if (endpoint.method === "POST") {
    return post(endpoint, headers, data);
  } else if (endpoint.method === "GET") {
    return get(endpoint, headers, data);
  } else if (endpoint.method === "PUT") {
    return put(endpoint, headers, data);
  } else if (endpoint.method === "DELETE") {
    return _delete(endpoint, headers, data);
  }
};
