// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.scrollable-note-list {
  min-height: 350px;
  border: 1px solid #dbdbdb;
  border-radius: 6px;
  padding: 5px;
  max-height: 300px; /* Adjust the height as needed */
  background-color: #f3f4f6;
  overflow-y: auto; /* Enable vertical scrolling */
}

.scrollable-note-list div.MuiButtonGroup-root {
  width: 100%;
  box-shadow: none;
  border-color: #cccdcd;
  border-width: 1px;
}

.scrollable-note-list button.MuiButtonBase-root {
  color: black;
  border-color: white;
  background-color: white;
}

.scrollable-note-list button.MuiButtonBase-root.main-button {
  width: 100%;
  text-transform: none;
}

.input-error {
  border-color: #ff3860 !important;
  animation: shake 0.5s ease-in-out;
}

.input-error::placeholder {
  color: #ff3860; /* Red placeholder text */
  opacity: 1;
}

@keyframes shake {
  0% {
    transform: translateX(10px);
  }
  25% {
    transform: translateX(-10px);
  }
  50% {
    transform: translateX(10px);
  }
  75% {
    transform: translateX(-10px);
  }
  100% {
    transform: translateX(0);
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/NoteList.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,yBAAyB;EACzB,kBAAkB;EAClB,YAAY;EACZ,iBAAiB,EAAE,gCAAgC;EACnD,yBAAyB;EACzB,gBAAgB,EAAE,8BAA8B;AAClD;;AAEA;EACE,WAAW;EACX,gBAAgB;EAChB,qBAAqB;EACrB,iBAAiB;AACnB;;AAEA;EACE,YAAY;EACZ,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,WAAW;EACX,oBAAoB;AACtB;;AAEA;EACE,gCAAgC;EAChC,iCAAiC;AACnC;;AAEA;EACE,cAAc,EAAE,yBAAyB;EACzC,UAAU;AACZ;;AAEA;EACE;IACE,2BAA2B;EAC7B;EACA;IACE,4BAA4B;EAC9B;EACA;IACE,2BAA2B;EAC7B;EACA;IACE,4BAA4B;EAC9B;EACA;IACE,wBAAwB;EAC1B;AACF","sourcesContent":[".scrollable-note-list {\r\n  min-height: 350px;\r\n  border: 1px solid #dbdbdb;\r\n  border-radius: 6px;\r\n  padding: 5px;\r\n  max-height: 300px; /* Adjust the height as needed */\r\n  background-color: #f3f4f6;\r\n  overflow-y: auto; /* Enable vertical scrolling */\r\n}\r\n\r\n.scrollable-note-list div.MuiButtonGroup-root {\r\n  width: 100%;\r\n  box-shadow: none;\r\n  border-color: #cccdcd;\r\n  border-width: 1px;\r\n}\r\n\r\n.scrollable-note-list button.MuiButtonBase-root {\r\n  color: black;\r\n  border-color: white;\r\n  background-color: white;\r\n}\r\n\r\n.scrollable-note-list button.MuiButtonBase-root.main-button {\r\n  width: 100%;\r\n  text-transform: none;\r\n}\r\n\r\n.input-error {\r\n  border-color: #ff3860 !important;\r\n  animation: shake 0.5s ease-in-out;\r\n}\r\n\r\n.input-error::placeholder {\r\n  color: #ff3860; /* Red placeholder text */\r\n  opacity: 1;\r\n}\r\n\r\n@keyframes shake {\r\n  0% {\r\n    transform: translateX(10px);\r\n  }\r\n  25% {\r\n    transform: translateX(-10px);\r\n  }\r\n  50% {\r\n    transform: translateX(10px);\r\n  }\r\n  75% {\r\n    transform: translateX(-10px);\r\n  }\r\n  100% {\r\n    transform: translateX(0);\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
