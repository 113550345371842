import React, { useEffect, useRef, useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

import NoteBox from "./NoteBox";
import NoteBar from "./NoteBar";
import NoteList from "./NoteList";
import ConversationFilter from "./ConversationFilter";
import NewConversation from "../pages/design_three/NewConversation";

import { CookiesProvider, useCookies } from "react-cookie";
import { getCredential, isUserAuthenticated } from "../utils/login";

import { getAllNotes } from "../apis/endpoints/Notes";
import {
  getConversationsFromNote,
  createTextConversation,
  createAudioConversation,
} from "../apis/endpoints/Conversation";
import {
  parseConversation,
  createPendingConversation,
} from "../utils/audioConversations";
/*
  In the backend you create Posts that contain Notes and subConversations
  In the frontend you create Notes that contain Conversations and subConversations
  old frontend you created Chats that contain Messages.

  Therefore:
  Notes == chats == posts, refer to the same thing
  &
  Conversation ==  messages == notes, refer to the same thing
*/

function NoteConversationGroup() {
  const [cookies, setCookie] = useCookies(["currentUser"]);
  const [backdrop, setBackdrop] = React.useState(false);
  const [notes, setNotes] = useState([]);
  const [selectedNote, setSelectedNote] = useState(null);

  // Should only invoke when a note list is set but no selected note was clicked, in this case,
  // We will load the first note in the user's account if it's available
  // useEffect(() => {
  //   // If the state of selected note id changes, update the display conversation list of that note
  //   if (selectedNote == null) {
  //     loadNote(notes[0] ?? null);
  //   }
  // }, [notes]);

  // Invoke when cookies data changed
  useEffect(() => {
    if (isUserAuthenticated(cookies)) {
      const cred = getCredential(cookies);
      handleGetNotes(cred.id, cred.email, cred.accessToken).then(results => {
        loadNote(results[0] ?? null);
      });
    } else {
      // If user no longer authenticated, clear all notes content
      setSelectedNote(null);
      setNotes([]);
    }
  }, [cookies]);

  // Call API to get list of available notes when the page is loaded
  // useEffect(() => {
  //   if (isUserAuthenticated(cookies)) {
  //     const cred = getCredential(cookies);
  //     const result = handleGetNotes(cred.id, cred.email, cred.accessToken);
  //   }
  // }, []);

  // given a targetNote if targetNote.conversations is Null then query for the conversations.
  // it will set the state of selecteNote to the have all conversations within targetNote
  const loadNote = (targetNote) => {
    // Check if current user is authenticated
    // TODO: Would be better if we can user function decorator for this
    // step in future changes

    if (!isUserAuthenticated(cookies)) {
      return;
    }

    if (targetNote == null) {
      // No note existed in user account
      setSelectedNote(targetNote);
    } else if (targetNote.conversations != null) {
      // No new note needed to be loaded, switch to local stored conversations
      setSelectedNote({ ...targetNote });

      // Update notes array. This is to handle the case where the selected note content got updated e.g.
      // a conversation was removed from the note, therefore, the local copy containing all notes should also
      // be updated to reflect this change
      const idx = notes.findIndex(n => n.id == targetNote.id)
      notes[idx] = targetNote;
    } else {
      // Query the conversations in this note, only run the query if there were no conversations in the note initially. This is to avoid
      // re-query the list of conversations everytime user click on the note
      setBackdrop(true);

      const cred = getCredential(cookies);
      getConversationsFromNote(
        cred.id,
        cred.email,
        cred.accessToken,
        targetNote.id,
        10,
        0,
        null,
        null,
        ["id", "audioFileName", "content", "timestamp", "name", "postId"]
      )
        .then((response) => {
          if (response.data != null) {
            // Parse the conversations in the note into an object compatible with the frontend
            const conversationData = response.data.map((conversation) =>
              parseConversation(conversation)
            );

            // Update the state of available notes
            targetNote.conversations = conversationData;
            setSelectedNote({ ...targetNote });
          }
        })
        .catch((error) => {
          console.error(
            "[NoteConversationGroup.js] Failed to get conversations from the current note",
            error
          );
        })
        .finally(() => {
          setBackdrop(false);
        });
    }
  };

  // Get list of notes in user account if the user is authenticated and
  // change the state of the notes to re-render on the screen
  const handleGetNotes = (userId, email, accessToken) => {
    setBackdrop(true);
    return getAllNotes(userId, email, accessToken)
      .then((response) => {
        // Handle case where the note list cannot be retrieved, API will return a null value, then we have an empty array of note
        response.data = response.data ?? [];

        const responseNotes = response.data.map((note) => {
          return {
            id: note.id,
            name: note.name,
            conversations: null,
            updatedAt: note.updatedAt ?? -1, // if updatedAt DNE it will return -1
          };
        });
        setNotes(responseNotes);
        return responseNotes;
      })
      .catch((error) => {
        console.error(
          "[NoteConversationGroup.js] Failed to retrieve the list of available notes",
          error
        );
        return [];
      })
      .finally((result) => {
        setBackdrop(false);
        return result;
      });
  };

  const addConversation = (newConversation) => {
    if (selectedNote == null || !isUserAuthenticated(cookies)) {
      return;
    }

    // Get API credential data
    const cred = getCredential(cookies);
    const userId = cred.id;
    const email = cred.email;
    const accessToken = cred.accessToken;

    if (newConversation.hasAudio) {
      const newConversationAudio = newConversation.audioName;
      const tag = newConversation.audioTag;

      const pendingConversation = createPendingConversation(
        createAudioConversation(
          userId,
          email,
          accessToken,
          newConversationAudio,
          selectedNote.id,
          tag
        )
      );
      selectedNote.conversations.push(pendingConversation);
      loadNote(selectedNote);
    } else {
      // Get conversation content. This is a text conversation so the API only takes in the raw text content
      const newConversationText = newConversation.text;

      // Create a pending conversation object and send it to note conversations list
      const pendingConversation = createPendingConversation(
        createTextConversation(
          userId,
          email,
          accessToken,
          newConversationText,
          selectedNote.id
        )
      );
      selectedNote.conversations.push(pendingConversation);
      loadNote(selectedNote);
    }
  };
  const labels = ["Important", "Work", "Personal", "Urgent"];
  // Array of matching IDs
  const [displayedMatches, setDisplayedMatches] = useState([]);
  const extractIds = (conversations) => {
    if (conversations == null) {
      return [];
    }
    // console.log("NoteConversationsGroup", conversations);

    const mainConversationIds = conversations.map((conversation) => {
      if (conversation != null) {
        conversation.id;
      }
    });

    // const subConversationIds = conversations
    //   .flatMap((conversation) =>
    //     conversation.subConversations ? conversation.subConversations : []
    //   ) // Get all subConversations arrays combined
    //   .map((subConversation) => subConversation.id); // Extract ids from subConversations

    // const subConversationIds = conversations
    //   .flatMap((conversation) =>
    //     conversation.subConversations ? conversation.subConversations : []
    //   ) // Get all subConversations arrays combined
    //   .map((subConversation) => subConversation.id); // Extract ids from subConversations

    // return [...mainConversationIds, ...subConversationIds];
    // console.log("main convo id", mainConversationIds);
    return [...mainConversationIds];
  };

  const loadAllConversations = () => {
    if (!isUserAuthenticated(cookies)) {
      return;
    }

    // Create promises for only the notes with null conversations
    const cred = getCredential(cookies);

    // The first note should already be loaded above, so we use slice(1) to skip it, this also seems to prevent a bug
    // where the conversation list flickers few times when the page first loaded
    notes.slice(1).forEach((note) => {
      if (!note.conversations) {
        getConversationsFromNote(
          cred.id,
          cred.email,
          cred.accessToken,
          note.id,
          10, // Limit
          0, // Offset
          null,
          null,
          ["id", "audioFileName", "content", "timestamp", "name", "postId"]
        )
          .then((response) => {
            if (response.code == 200 && response.data != null) {
              // Update the note's conversations
              note.conversations = response.data.map(parseConversation);
            }
          })
          .catch((error) => {
            console.error(
              `Error loading conversations for note ${note.id}`,
              error
            );
          });
      }
    });
  };

  return (
    <div className="columns">
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <div className="column is-one-fifth">
        <ConversationFilter
          labels={labels}
          selectedNote={selectedNote}
          allNotes={notes}
          setDisplayedMatches={setDisplayedMatches} // passsing the setter
          loadAllConversations={loadAllConversations}
        />
        <NoteList
          notes={notes}
          setNotes={setNotes}
          selectedNote={selectedNote}
          displayedMatches={displayedMatches}
          loadNote={loadNote}
          setSelectedNote={setSelectedNote} // Add this line
        />
      </div>

      <div className="column">
        <NoteBar
          addConversation={addConversation}
          selectedNote={selectedNote}
          reloadNoteList={handleGetNotes}
        />
        <NoteBox
          notes={notes}
          selectedNote={selectedNote}
          displayedMatches={displayedMatches}
          loadNote={loadNote}
        />
      </div>
    </div>
  );
}

export default NoteConversationGroup;
