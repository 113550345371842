// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.record-container {
  padding-top: 10px;
  text-align: center;
  margin: auto;
}

div#sound-wave {
  margin-top: 10px;
  position: absolute;
  /* align-items: center;
  align-content: center;
  text-align: center; */
  /* z-index: -10; */
}

button.MuiButtonBase-root#record {
  background-color: rgb(255, 0, 0);
  color: #ffff;
  border-radius: 100px;
  padding: 8px;
  font-size: 16px;
  min-width: 60px;
  min-height: 60px;
  align-items: center;
  /* transition: */
}

button.MuiButtonBase-root#record:hover {
  color: white;
  background-color: red;
  animation-name: pulse;
  animation-duration: 1.0s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

/* button.MuiButtonBase-root#record[recording="true"] {
  border: 2px solid white;
  color: white;
  background-color: red;
  animation-name: pulse;
  animation-duration: 1.25s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
} */

@keyframes pulse {
  0% {
    box-shadow: 0px 0px 5px 0px rgba(173, 0, 0, 0.3);
  }
  65% {
    box-shadow: 0px 0px 5px 13px rgba(173, 0, 0, 0.3);
  }
  90% {
    box-shadow: 0px 0px 5px 13px rgba(173, 0, 0, 0);
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/NoteBar.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,kBAAkB;EAClB,YAAY;AACd;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;EAClB;;uBAEqB;EACrB,kBAAkB;AACpB;;AAEA;EACE,gCAAgC;EAChC,YAAY;EACZ,oBAAoB;EACpB,YAAY;EACZ,eAAe;EACf,eAAe;EACf,gBAAgB;EAChB,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,qBAAqB;EACrB,qBAAqB;EACrB,wBAAwB;EACxB,mCAAmC;EACnC,iCAAiC;AACnC;;AAEA;;;;;;;;GAQG;;AAEH;EACE;IACE,gDAAgD;EAClD;EACA;IACE,iDAAiD;EACnD;EACA;IACE,+CAA+C;EACjD;AACF","sourcesContent":["div.record-container {\r\n  padding-top: 10px;\r\n  text-align: center;\r\n  margin: auto;\r\n}\r\n\r\ndiv#sound-wave {\r\n  margin-top: 10px;\r\n  position: absolute;\r\n  /* align-items: center;\r\n  align-content: center;\r\n  text-align: center; */\r\n  /* z-index: -10; */\r\n}\r\n\r\nbutton.MuiButtonBase-root#record {\r\n  background-color: rgb(255, 0, 0);\r\n  color: #ffff;\r\n  border-radius: 100px;\r\n  padding: 8px;\r\n  font-size: 16px;\r\n  min-width: 60px;\r\n  min-height: 60px;\r\n  align-items: center;\r\n  /* transition: */\r\n}\r\n\r\nbutton.MuiButtonBase-root#record:hover {\r\n  color: white;\r\n  background-color: red;\r\n  animation-name: pulse;\r\n  animation-duration: 1.0s;\r\n  animation-iteration-count: infinite;\r\n  animation-timing-function: linear;\r\n}\r\n\r\n/* button.MuiButtonBase-root#record[recording=\"true\"] {\r\n  border: 2px solid white;\r\n  color: white;\r\n  background-color: red;\r\n  animation-name: pulse;\r\n  animation-duration: 1.25s;\r\n  animation-iteration-count: infinite;\r\n  animation-timing-function: linear;\r\n} */\r\n\r\n@keyframes pulse {\r\n  0% {\r\n    box-shadow: 0px 0px 5px 0px rgba(173, 0, 0, 0.3);\r\n  }\r\n  65% {\r\n    box-shadow: 0px 0px 5px 13px rgba(173, 0, 0, 0.3);\r\n  }\r\n  90% {\r\n    box-shadow: 0px 0px 5px 13px rgba(173, 0, 0, 0);\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
