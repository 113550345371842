import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { Button, Card, CardContent, Typography, Grid2, Box, Select, MenuItem, FormControl, InputLabel, CardHeader, List, ListItem } from '@mui/material';
import Backdrop from "@mui/material/Backdrop";
import Link from '@mui/material/Link';
import CardMedia from '@mui/material/CardMedia';
import CardActionArea from '@mui/material/CardActionArea';
import CheckIcon from '@mui/icons-material/Check';
import CircularProgress from "@mui/material/CircularProgress";

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import {
  isPremiumActive,
  getPremiumEndDate,
  getSubscriptions,
  resumeSubscription,
  pauseSubscription,
  cancelSubscription
} from "../apis/endpoints/Payments";
import { getCredential, isUserAuthenticated } from "../utils/login";
import { CookiesProvider, useCookies } from "react-cookie";

const SubscriptionPage = () => {
  const plans = {
    onetime: {
      name: 'One-time',
      price: '$30'
    },
    monthly: {
      name: 'Monthly',
      price: '$3/month'
    }
  };

  const [backdrop, setBackdrop] = React.useState(false);
  const [cookies, setCookie] = useCookies(["currentUser"]);
  const credential = getCredential(cookies);
  const navigate = useNavigate();

  const [currentPaymentPlan, setCurrentPaymentPlan] = useState(null);
  const [subscriptionId, setSubscriptionId] = useState(null);
  const [subscriptionMessage, setSubscriptionMessage] = useState('Free plan');
  const [subscriptionEndDate, setSubscriptionEndDate] = useState(new Date()); // The date for the next subscription payment

  // RESUME / PAUSE SUBSCRIPTION
  const [isPaymentCollectionActive, setPaymentCollectionStatus] = useState(false);
  const [isSubscriptionCancelAtPeriodEnd, setSubscriptionCancelAtPeriodEnd] = useState(false);
  const toggleSubscription = () => {
    if (isUserAuthenticated(cookies)) {
      const cred = getCredential(cookies);
      if (isPaymentCollectionActive && !isSubscriptionCancelAtPeriodEnd) {
        return pauseSubscription(cred.id, cred.email, cred.accessToken, subscriptionId).then(result => {
          if (result.code == 200 && result.data) {
            setPaymentCollectionStatus(false);
          }
        }).catch(err => {
          console.error("[SubscriptionPage.js] Failed to pause subscription", err);
        });
      } else {
        return resumeSubscription(cred.id, cred.email, cred.accessToken, subscriptionId).then(result => {
          if (result.code == 200 && result.data) {
            setPaymentCollectionStatus(true);
            setSubscriptionCancelAtPeriodEnd(false);
          }
        }).catch(err => {
          console.error("[SubscriptionPage.js] Failed to resume subscription", err);
        });
      }
    }
  }
  const [openToggleSubscriptionDialog, setOpenToggleSubscriptionDialog] = React.useState(false);
  const handleClickOpenToggleDialog = () => {
    setOpenToggleSubscriptionDialog(true);
  };
  const handleCloseToggleDialog = () => {
    setOpenToggleSubscriptionDialog(false);
  };

  // CANCEL SUBSCRIPTION
  const handleCancelSubscriptionClick = () => {
    if (isUserAuthenticated(cookies)) {
      const cred = getCredential(cookies);
      return cancelSubscription(cred.id, cred.email, cred.accessToken, subscriptionId).then(result => {
        if (result.code == 200 && result.data) {
          setSubscriptionCancelAtPeriodEnd(true);
        }
      }).catch(err => {
        console.error("[SubscriptionPage.js] Failed to cancel subscription", err);
      });
    }
  };
  const [openCancelSubscriptionDialog, setOpenCancelSubscriptionDialog] = React.useState(false);
  const handleClickOpenCancelDialog = () => {
    setOpenCancelSubscriptionDialog(true);
  };
  const handleCloseCancelDialog = () => {
    setOpenCancelSubscriptionDialog(false);
  };

  useEffect(() => {
    const loadData = async () => {
      if (isUserAuthenticated(cookies)) {
        setBackdrop(true);
        const cred = getCredential(cookies);
        const result = await getSubscriptions(cred.id, cred.email, cred.accessToken).catch(err => {
          console.error("[SubscriptionPage.js] Failed to get subscription", err);
        });

        if (result.code == 200 && result.data.length > 0) {
          setSubscriptionId(result.data[0].id);
          setSubscriptionEndDate(new Date(result.data[0].current_period_end * 1000));
          setPaymentCollectionStatus(result.data[0].pause_collection == null); // null = active payment collection
          setSubscriptionCancelAtPeriodEnd(result.data[0].cancel_at_period_end);
        }

        const checkPaymentPlanResult = await isPremiumActive(cred.id, cred.email, cred.accessToken).catch(err => {
          console.error("[SubscriptionPage.js] Failed to get premium status", err);
        });
        if (checkPaymentPlanResult.code == 200 && checkPaymentPlanResult.data) {
          if (checkPaymentPlanResult.data && result.data.length > 0 && result.data[0].id) {
            setSubscriptionMessage("Monthly payment plan");
            setCurrentPaymentPlan(plans.monthly);
          } else if (checkPaymentPlanResult.data && result.data.length == 0) {
            setSubscriptionMessage("One-time payment plan");
            setCurrentPaymentPlan(plans.onetime);
          } else {
            setSubscriptionMessage("Free plan");
          }
        }
        setBackdrop(false);
      }
    };

    loadData();
  }, []);

  return (
    <section className="section">
      <div className="container">
        <Box sx={{ padding: 4 }}>
          <Link
            component="button"
            variant="h5"
            onClick={() => navigate("/")}
            sx={{ mb: 2 }}
          >
            {`< Home`}
          </Link>
          <Typography variant="h4" gutterBottom>
            Manage Your Subscription
          </Typography>

          <Card sx={{ mb: 4 }}>
            <CardHeader
              title={`Current Plan: ${subscriptionMessage}`}
              subheader={
                <Typography variant="h7">
                  {(currentPaymentPlan == null) ? "Premium status not active" : (currentPaymentPlan.name == "One-time") ? "Life-time premium account" : `Your premium status is active until ${subscriptionEndDate.toDateString()}`}
                </Typography>
              }
              subheaderTypographyProps={{ color: 'white' }}
              sx={{ backgroundColor: "primary.main", color: "white"}}
            />
            <CardContent>
              {currentPaymentPlan && subscriptionId &&
                <Grid2 container spacing={2}>
                  <Grid2 item xs={8}>
                    <Typography variant="body1">
                      <CheckIcon/> 1 verified Premium account
                      <br />
                      <CheckIcon/> Unlimited note-taking
                      <br />
                      <CheckIcon/> Cancel anytime
                    </Typography>
                  </Grid2>
                  <Grid2 item xs={4}>
                    <Typography variant="h6">
                      Payment
                    </Typography>
                    <Typography variant="body1" sx={{ color: 'text.secondary' }}>
                      {isSubscriptionCancelAtPeriodEnd ? `Your subscription will be canceled on ${subscriptionEndDate.toDateString()}` : `Your next bill is for ${currentPaymentPlan.price} on ${subscriptionEndDate.toDateString()}`}
                    </Typography>
                    {isPaymentCollectionActive && !isSubscriptionCancelAtPeriodEnd && <Button variant="contained" color="primary" onClick={handleClickOpenToggleDialog} sx={{ mr: 1, mb: 1 }}>Pause Subscription</Button>}
                    {(!isPaymentCollectionActive || isSubscriptionCancelAtPeriodEnd) && <Button variant="contained" color="primary" onClick={handleClickOpenToggleDialog} sx={{ mr: 1, mb: 1 }}>Resume Subscription</Button>}
                    {!isSubscriptionCancelAtPeriodEnd && <Button variant="outlined" onClick={handleClickOpenCancelDialog} sx={{ mr: 1, mb: 1 }}>Cancel Subscription</Button>}
                  </Grid2>
                </Grid2>}

              {currentPaymentPlan && (subscriptionId == null) &&
                <Grid2 container spacing={2}>
                  <Grid2 item xs={12}>
                    <Typography variant="body1">
                      <CheckIcon/> 1 verified Premium account
                      <br />
                      <CheckIcon/> Life-time support
                      <br />
                      <CheckIcon/> Unlimited note-taking
                    </Typography>
                  </Grid2>
                </Grid2>}

              {(currentPaymentPlan == null) && (subscriptionId == null) &&
                <Grid2 container spacing={8} alignItems="center" justifyContent="center">
                  <Grid2 item xs={6}>
                    <Typography variant="body1">
                      <CheckIcon/> 1 verified Premium account
                      <br />
                      <CheckIcon/> One-time payment
                      <br />
                      <CheckIcon/> Unlimited note-taking
                    </Typography>

                    <div style={{textAlign: 'center'}}>
                      <Typography variant="h6">
                        IntelliNotes+
                      </Typography>
                      <Typography variant="h5" display="inline">
                        <b>$30 </b>
                      </Typography>
                      <Button variant="contained" href={`${process.env.REACT_APP_INTELLINOTES_ONE_TIME_PAYMENT_LINK}?prefilled_email=${credential.email}`} fullWidth sx={{ mr: 1, mb: 1, mt: 1 }}>Buy</Button>
                    </div>
                  </Grid2>
                  <Grid2 item xs={6}>
                    <Typography variant="body1">
                      <CheckIcon/> 1 verified Premium account
                      <br />
                      <CheckIcon/> Monthly payment
                      <br />
                      <CheckIcon/> Unlimited note-taking
                    </Typography>

                    <div style={{textAlign: 'center'}}>
                      <Typography variant="h6">
                        Monthly IntelliNotes+
                      </Typography>
                      <Typography variant="h5" display="inline">
                        <b>$3 </b>
                      </Typography>
                      <Typography variant="body" display="inline">
                        per month
                      </Typography>
                      <Button variant="contained" href={`${process.env.REACT_APP_INTELLINOTES_MONTHLY_PAYMENT_LINK}?prefilled_email=${credential.email}`} fullWidth sx={{ mr: 1, mb: 1, mt: 1 }}>Subscribe</Button>
                    </div>
                  </Grid2>
                </Grid2>}
            </CardContent>
          </Card>
        </Box>

        <Dialog
          open={openToggleSubscriptionDialog}
          onClose={handleCloseToggleDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {(isPaymentCollectionActive && !isSubscriptionCancelAtPeriodEnd) ? "Pause monthly subscription?" : "Resume monthly subscription?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {(isPaymentCollectionActive && !isSubscriptionCancelAtPeriodEnd) ?
                `Do you want to pause your IntelliNotes premium subscription? Your premium status will still be active until ${subscriptionEndDate.toDateString()}`
                : `Do you want to resume your IntelliNotes premium subscription?`}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseToggleDialog}>Cancel</Button>
            <Button onClick={() => toggleSubscription().then(handleCloseToggleDialog)} autoFocus>
              Ok
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={openCancelSubscriptionDialog}
          onClose={handleCloseCancelDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Cancel monthly subscription?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {`Do you want to cancel your IntelliNotes premium subscription? Your premium status will still be active until ${subscriptionEndDate.toDateString()}`}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseCancelDialog}>Cancel</Button>
            <Button onClick={() => handleCancelSubscriptionClick().then(handleCloseCancelDialog)} autoFocus>
              Ok
            </Button>
          </DialogActions>
        </Dialog>

        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={backdrop}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
    </section>
  );
};

export default SubscriptionPage;
