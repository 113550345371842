import React, { useState, useRef, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Icon from "./Icon";
import $ from "jquery";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { CookiesProvider, useCookies } from "react-cookie";
import { GoogleLoginModal } from "./GoogleLoginComponent/GoogleLoginComponent";
import { isUserAuthenticated, getCredential } from "../utils/login";
import { Box, Typography, IconButton } from '@mui/material';
import MicIcon from '@mui/icons-material/Mic';
import StopIcon from '@mui/icons-material/Stop';
import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';

import { PremiumStatusCtx } from "../contexts";

import { getAllNotes, createNewNote } from "../apis/endpoints/Notes";
import {
  getConversationsFromNote,
  createAudioConversation,
  countConversationFromNote,
} from "../apis/endpoints/Conversation";

//Firebase Imports
import { analytics } from "../App";
import { logEvent } from "firebase/analytics";

import "./NoteBar.css";

function NoteBar({ addConversation, selectedNote, reloadNoteList }) {
  const audioTags = [
    { key: "summary", value: "Summary" },
    {key: "todolist", value: "To-do list"},
    // {key: "email", value: "Email"},
    // {key: "blog", value: "Blog"}
  ];
  const [newConversation, setNewConversation] = useState({
    text: "",
    name: "Tester",
    hasAudio: false,
    audioName: "",
  });

  const { isPremiumStatusActive, setPremiumStatus } = useContext(PremiumStatusCtx);
  const [cookies, setCookie] = useCookies(["currentUser"]);
  const navigate = useNavigate();
  const [openLoginModal, setOpenLoginModal] = useState(false);
  const [backdrop, setBackdrop] = useState(false);

  const [recording, setRecording] = useState(false);
  const [hasMicAccess, setHasMicAccess] = useState(true);
  const mediaRecorder = useRef(null);
  const audioChunks = useRef([]);
  const noiseLevelRef = useRef(0);
  const conversationBarRef = useRef(null);
  const [isRunning, setIsRunning] = useState(false);
  const [seconds, setSeconds] = useState(0);
  const tagRef = useRef(""); // Use this to pass the tag of the conversation in this component into the GoogleLoginModal
  let startRecordingAudio = new Audio("./assets/StartRecording.mp3");
  let stopRecordingAudio = new Audio("./assets/StopRecording.mp3");

  // Modal asking for purchasing subscription when user reach maximum # of allowed conversations
  const [openSubscriptionAlert, setOpenSubscriptionAlert] = useState(false);
  const handleOpenSubscriptionAlert = () => {
    setOpenSubscriptionAlert(true);
  };
  const handleCloseSubscriptionAlert = () => {
    setOpenSubscriptionAlert(false);
  };

  const countAllConversations = async (credential) => {
    const count = await countConversationFromNote(credential.id, credential.email, credential.accessToken).then(result => {
      if (result.code == 200 && (result.data != null)) {
        return result.data;
      }
    }).catch(error => {
      console.error(
        "[ConversationBar.js] Failed to count number of conversations",
        error
      );
      return 0;
    });

    return count;
  }

  useEffect(() => {
    let interval;

    if (isRunning) {
      interval = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds + 1);
      }, 1000);
    } else {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [isRunning]);

  const formatTime = (time) => {
    const getSeconds = `0${time % 60}`.slice(-2);
    const minutes = `${Math.floor(time / 60)}`;
    const getMinutes = `0${minutes % 60}`.slice(-2);
    const getHours = `0${Math.floor(time / 3600)}`.slice(-2);

    return `${getHours} : ${getMinutes} : ${getSeconds}`;
  };

  const handleRecordClick = () => {
    setIsRunning((prevIsRunning) => !prevIsRunning);
    analytics &&
      logEvent(analytics, "recordbuttonclick", {
        item_list_name: "record-button",
      });
  };

  const handleResetClick = () => {
    setSeconds(0);
    setIsRunning(false);
  };

  // This code implements the logic when user first open the app, it asks user for
  // mic permission right away instead of waiting until they hit the record button.
  // This is done by creating a recording user media and then stops it immediately
  useEffect(() => {
    const getMicrophoneAccess = () => {
      navigator.mediaDevices
        .getUserMedia({
          audio: true,
        })
        .then((stream) => {
          stream.getTracks().forEach((track) => {
            track.enabled = false;
            track.stop();
          });
          setHasMicAccess(true);
        })
        .catch((error) => {
          console.error(
            "[ConversationBar.js] Error accessing microphone",
            error
          );
          setHasMicAccess(false);
        });
    };
    getMicrophoneAccess();
  }, []);

  // This function implements the logic to create a default note and upload the audio conversation to
  // that note. The use case for this is when a user hasn't registered yet, so they don't have any notes, or
  // user has registered but they don't have any note in their account when they click recording button
  const handleRecordWithoutNote = async (userId, email, accessToken) => {
    setBackdrop(true);
    const newNoteResponse = await createNewNote(userId, email, accessToken, "(default)").catch((error) => {
      console.error("[NoteBar.js] Failed to create a default note");
    });

    if (newNoteResponse.code == 200 && newNoteResponse.data != null) {
      const newNoteId = newNoteResponse.data;
      const audioBlob = new Blob(audioChunks.current, {
        type: "audio/mp3",
      });
      const createConvResponse = await createAudioConversation(
        userId,
        email,
        accessToken,
        audioBlob,
        newNoteId,
        tagRef.current
      ).catch((error) => {
        console.error(
          `[NoteBar.js] Failed to create audio conversation for tag ${tagRef.current}`
        );
      }).finally(() => {
        reloadNoteList(userId, email, accessToken);
      });
      setBackdrop(false);
    }
  };

  // This function implements the following logic: User makes record a conversation & there exists
  // a note in their account, this should bring up a dialog for user to choose whether they want to add the conversation into an
  // existing note or to create a new note to store this conversation
  const handleRecordWithExistingNote = async (userId, email, accessToken) => {

  }

  // After user record a note but they haven't logged in/registered account. A modal will popup
  // if they click the button to login using Google, this will trigger the callback function below
  const onRegisterAfterStopRecording = async (credential, tagRef) => {
    if (setOpenLoginModal(false) || credential != null) {
      setBackdrop(true);
      const notesResponse = await getAllNotes(credential.id, credential.email, credential.accessToken).catch((error) => {
        console.error(
          "[ConversationBar.js] Failed to load user's note list",
          error
        );
      }).finally(() => {
        setBackdrop(false);
      });

      const totalConversations = await countAllConversations(credential);
      if (isPremiumStatusActive || (!isPremiumStatusActive && totalConversations < 3)) {
        if (notesResponse.code == 200 && notesResponse.data) {
          if (notesResponse.data.length == 0) {
            // If no note existed in user's account, create a default note & add the recorded conversation into it
            await handleRecordWithoutNote(
              credential.id,
              credential.email,
              credential.accessToken
            );
          } else {
            // Prompt user to select the note that they want to store the new conversation
            // TODO: FEATURE NOT IMPLEMENTED
            await handleRecordWithExistingNote(
              credential.id,
              credential.email,
              credential.accessToken
            );
          }
        }
      } else {
        // Premium is not active & user already exceed the total number of conversations allowed
        handleOpenSubscriptionAlert();
      }
    }
  };

  const startRecording = (tag) => {
    return navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        mediaRecorder.current = new MediaRecorder(stream);
        audioChunks.current = [];

        // Create audio analyzer node to measure the amplitude during the amplitude. This
        // value is used to visualize the sound wave
        const audioContext = new AudioContext();
        const mediaStreamAudioSourceNode = audioContext.createMediaStreamSource(stream);
        const analyserNode = audioContext.createAnalyser();
        mediaStreamAudioSourceNode.connect(analyserNode);
        const pcmData = new Float32Array(analyserNode.fftSize);

        startRecordingAudio.play();
        mediaRecorder.current.ondataavailable = (event) => {
          audioChunks.current.push(event.data);

          // This code is used to measure the noise level of each audio chunk
          // during recording session. The noise level is used to visualize the
          // voice wave animation
          analyserNode.getFloatTimeDomainData(pcmData);
          let sumSquares = 0.0;
          for (const amplitude of pcmData) {
            sumSquares += amplitude * amplitude;
          }
          const level = Math.sqrt(sumSquares / pcmData.length);
          noiseLevelRef.current = level;
        };

        mediaRecorder.current.onstop = async () => {
          // Stop mic
          stream.getTracks().forEach((track) => {
            track.enabled = false;
            track.stop();
          });
          noiseLevelRef.current = 0; // Set noise level to 0

          // This blob is needed for transcribing the audio in the backend.
          const audioBlob = new Blob(audioChunks.current, {
            type: "audio/mp3",
          });

          tagRef.current = tag;
          if (isUserAuthenticated(cookies)) {
            const cred = getCredential(cookies);
            const totalConversations = await countAllConversations(cred);

            if (isPremiumStatusActive || (!isPremiumStatusActive && totalConversations < 3)) {
              if (selectedNote == null) {
                // User is logged in but the no note is selected, this should create a
                // default note to hold their recording
                handleRecordWithoutNote(cred.id, cred.email, cred.accessToken);
              } else {
                // Call sendAudioConversation with the audio blob and audio url
                sendAudioConversation(audioBlob, tag);
              }
            } else {
              // Premium is not active & user already exceed the total number of conversations allowed
              handleOpenSubscriptionAlert();
            }
          } else {
            // Open the modal to ask for user to sign in
            setOpenLoginModal(true);
          }
        };

        handleRecordClick();
        mediaRecorder.current.start(100);
        setRecording(true);
      })
      .catch((error) => {
        console.error("[ConversationBar.js] Error accessing microphone", error);
        setHasMicAccess(false);
      });
  };

  const stopRecording = () => {
    stopRecordingAudio.play();
    handleResetClick();
    mediaRecorder.current.stop();
    setRecording(false);
  };

  const sendAudioConversation = async (audioBlob, tag) => {
    if (newConversation.name.trim() !== "") {
      addConversation({
        text: "AudioFile",
        name: "Tester",
        hasAudio: true,
        audioName: audioBlob,
        audioTag: tag,
      });
      setNewConversation({
        text: "",
        name: "Tester",
        hasAudio: false,
        audioName: "",
      });
    }
  };

  /* Dropdown menu when clicking record button */
  const [anchorRecord, setAnchorRecord] = React.useState(null);
  const isMenuOpen = Boolean(anchorRecord);
  const handleRecordTag = (event, tag) => {
    setAnchorRecord(null); // Hide the menu
    startRecording(tag); // Start recording
    // Set the recording button status, this effectively affects the CSS styling of the button in NoteBar.css
    $("button.MuiButtonBase-root#record").attr("recording", true);
  };
  const handleClickRecordButton = (event) => {
    if (!recording) {
      // Currently in not recording state, display dropdown menu to allow user choose type of note
      setAnchorRecord(event.currentTarget);
    } else {
      // Stop recording, do not display the dropdown menu
      stopRecording();
      $("button.MuiButtonBase-root#record").attr("recording", false);
    }
  };

  const resumeRecording = () => {
    mediaRecorder.current.resume();
    startRecordingAudio.play();
    setIsRunning(true);
  };

  const pauseRecording = () => {
    mediaRecorder.current.pause();
    stopRecordingAudio.play();
    setIsRunning(false);
  };

  return (
    <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      marginBottom: '2rem',
      padding: '1rem',
      borderRadius: '8px',
      boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
      backgroundColor: '#e0f2f1',
    }}
    >
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Typography variant="h5" sx={{ flexGrow: 1 }}>
        Record a New Voice Note
      </Typography>
      {hasMicAccess ? (
        <div> {recording ? (
          <div>
            <div className="record-container">
              <div>
                <b>Recording Time: {formatTime(seconds)}</b>
              </div>
              <IconButton
                color="error"
                onClick={stopRecording}
                sx={{ backgroundColor: '#004d40', color: '#ffffff', marginRight: '1rem' }}
              >
                <StopIcon />
              </IconButton>
              <IconButton
                color="primary"
                onClick={isRunning ? pauseRecording : resumeRecording}
                sx={{ backgroundColor: isRunning ? '#00695c' : '#008080', color: '#ffffff' }}
              >
                {isRunning ? <PauseIcon />: <PlayArrowIcon />}
              </IconButton>
            </div>
          </div>
        ) : (
        <div className="">
          <GoogleLoginModal openModal={openLoginModal} setOpenModal={setOpenLoginModal} callback={(cred) => onRegisterAfterStopRecording(cred, tagRef)}/>
          <Button
            id="record"
            aria-controls={isMenuOpen ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={isMenuOpen ? 'true' : undefined}
            onClick={handleClickRecordButton}
          >
            <MicIcon />
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorRecord}
            open={isMenuOpen}
            onClose={() => setAnchorRecord(null)}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
          >
            {audioTags.map(entry => (
              <MenuItem key={entry.key} onClick={(event) => handleRecordTag(event, entry.key)}>{entry.value}</MenuItem>
            ))
            }
          </Menu>
        </div>
      )}
        </div>
      ) : (
        <div className = "text-center">
          <b>Need Mic Access To Record A Note</b>
        </div>
      )}

      <Dialog
        open={openSubscriptionAlert}
        onClose={handleCloseSubscriptionAlert}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Purchase your premium"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            You've reached the limit for recorded conversations. To keep enjoying our service, please consider upgrading to a premium plan.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseSubscriptionAlert}>Close</Button>
          <Button onClick={() => navigate("/subscription")} autoFocus>
            Manage Subscription
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
export default NoteBar;
