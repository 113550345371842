import {
  CREATE_ACCOUNT_ENDPOINT,
  GET_ACCOUNT_ENDPOINT
} from "../utils/constants.js";

import { makeRequest, generateAuthHeader } from "../utils/utils.js";

const BACKEND_SECRET = process.env.REACT_APP_BACKEND_SECRET;

/**
 * Call API to create user's account using Google's login credential data
 * @param {string} userId The unique identifier for the user
 * @param {string} email The email address associated with the user
 * @param {string} name User's account first name
 * @param {string} profilePic URL to profile picture
 * @return {}
 */
export const createAccount = async (userId, email, name, profilePic) => {
  return makeRequest(
    CREATE_ACCOUNT_ENDPOINT,
    generateAuthHeader(null, null, null, {
      "X-Access-Token": BACKEND_SECRET
    }),
    {
      userId: userId,
      email: email,
      name: name,
      profilePic: profilePic
    }
  );
};

/**
 * Call API to get user's account using Google's account unique identifier
 * @param {string} userId The unique identifier for the user
 * @param {string[]} parameters The array of containing names of the field in user's account data
 * @return {}
 */
export const getAccount = async (userId, parameters) => {
  return makeRequest(
    GET_ACCOUNT_ENDPOINT,
    generateAuthHeader(null, null, null, {
      "X-Access-Token": BACKEND_SECRET
    }),
    {
      userId: userId,
      parameters: parameters.join(",")
    }
  );
};
