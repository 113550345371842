import React, { useState } from "react";
import { Box, Typography, IconButton } from "@mui/material";
import MicIcon from "@mui/icons-material/Mic";
import StopIcon from "@mui/icons-material/Stop";
import PauseIcon from "@mui/icons-material/Pause";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";

const NewConversation = () => {
  const [isRecording, setIsRecording] = useState(false);
  const [isPaused, setIsPaused] = useState(false);

  const handleStartRecording = () => {
    setIsRecording(true);
    setIsPaused(false);
    // Add logic to start recording
  };

  const handleStopRecording = () => {
    setIsRecording(false);
    setIsPaused(false);
    // Add logic to stop recording
  };

  const handlePauseRecording = () => {
    setIsPaused(true);
    // Add logic to pause recording
  };

  const handleResumeRecording = () => {
    setIsPaused(false);
    // Add logic to resume recording
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        marginBottom: "2rem",
        padding: "1rem",
        borderRadius: "8px",
        boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
        backgroundColor: "#e0f2f1",
      }}
    >
      <Typography variant="h5" sx={{ flexGrow: 1 }}>
        Record a New Voice Note
      </Typography>

      {isRecording ? (
        <>
          <IconButton
            color="error"
            onClick={handleStopRecording}
            sx={{
              backgroundColor: "#004d40",
              color: "#ffffff",
              marginRight: "1rem",
            }}
          >
            <StopIcon />
          </IconButton>
          <IconButton
            color="primary"
            onClick={isPaused ? handleResumeRecording : handlePauseRecording}
            sx={{
              backgroundColor: isPaused ? "#008080" : "#00695c",
              color: "#ffffff",
            }}
          >
            {isPaused ? <PlayArrowIcon /> : <PauseIcon />}
          </IconButton>
        </>
      ) : (
        <IconButton
          color="primary"
          onClick={handleStartRecording}
          sx={{ backgroundColor: "#008080", color: "#ffffff" }}
        >
          <MicIcon />
        </IconButton>
      )}
    </Box>
  );
};

export default NewConversation;
