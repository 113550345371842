export const BACKEND_BASE_URL = process.env.REACT_APP_BACKEND_URL;

// Helper function
const createEndpointHelper = (method, url) => {
  return {
    method: method,
    url: url,
  };
};

// API Endpoints related to conversations
const NOTE_NAMEPSACE = "note";
export const GET_NOTE_COUNT_ENDPOINT = createEndpointHelper("GET", `${BACKEND_BASE_URL}/${NOTE_NAMEPSACE}/api/count`);
export const CREATE_AUDIO_NOTE_ENDPOINT = createEndpointHelper("POST", `${BACKEND_BASE_URL}/${NOTE_NAMEPSACE}/api/create/audio`);
export const CREATE_TEXT_NOTE_ENDPOINT = createEndpointHelper("POST", `${BACKEND_BASE_URL}/${NOTE_NAMEPSACE}/api/create/text`);
export const DELETE_NOTE_ENDPOINT = createEndpointHelper("DELETE", `${BACKEND_BASE_URL}/${NOTE_NAMEPSACE}/api/delete`);
export const GET_AUDIO_SIGNED_URL_ENDPOINT = createEndpointHelper("GET", `${BACKEND_BASE_URL}/${NOTE_NAMEPSACE}/api/get/audio`);
export const GET_NOTES_FROM_CHAT_ENPOINT = createEndpointHelper("GET", `${BACKEND_BASE_URL}/${NOTE_NAMEPSACE}/api/get/from-post`);
export const GET_PARSED_NOTE_ENDPOINT = createEndpointHelper("GET", `${BACKEND_BASE_URL}/${NOTE_NAMEPSACE}/api/get/parsed-text`);
export const GET_SINGLE_NOTE_FROM_CHAT_ENDPOINT = createEndpointHelper("GET", `${BACKEND_BASE_URL}/${NOTE_NAMEPSACE}/api/get/single-from-post`);
export const GET_OTHER_USER_SINGLE_NOTE_ENDPOINT = createEndpointHelper("GET", `${BACKEND_BASE_URL}/${NOTE_NAMEPSACE}/api/get/single/from-user`);
export const GET_RAW_NOTE_ENDPOINT = createEndpointHelper("GET", `${BACKEND_BASE_URL}/${NOTE_NAMEPSACE}/api/get/text`);
export const UPDATE_NOTE_ENDPOINT = createEndpointHelper("PUT", `${BACKEND_BASE_URL}/${NOTE_NAMEPSACE}/api/update`);

// API Endpoints related to subconversations
const SUBNOTE_NAMESPACE = "subnotes"
export const CREATE_AUDIO_SUBNOTE_ENDPOINT = createEndpointHelper("POST", `${BACKEND_BASE_URL}/${SUBNOTE_NAMESPACE}/api/create/audio`);
export const DELETE_SUBNOTE_ENDPOINT = createEndpointHelper("POST", `${BACKEND_BASE_URL}/${SUBNOTE_NAMESPACE}/api/delete`);
export const GET_SUBNOTE_AUDIO_ENDPOINT = createEndpointHelper("GET", `${BACKEND_BASE_URL}/${SUBNOTE_NAMESPACE}/api/get/audio`);
export const GET_OTHER_USER_SUBNOTE_AUDIO_ENDPOINT = createEndpointHelper("GET", `${BACKEND_BASE_URL}/${SUBNOTE_NAMESPACE}/api/get/audio/from-user`);
export const GET_MULTIPLE_SUBNOTES_ENDPOINT = createEndpointHelper("GET", `${BACKEND_BASE_URL}/${SUBNOTE_NAMESPACE}/api/multiple`);
export const GET_OTHER_USER_MULTIPLE_SUBNOTES_ENDPOINT = createEndpointHelper("GET", `${BACKEND_BASE_URL}/${SUBNOTE_NAMESPACE}/api/get/multiple/from-user`);
export const GET_SINGLE_SUBNOTE_ENDPOINT = createEndpointHelper("GET", `${BACKEND_BASE_URL}/${SUBNOTE_NAMESPACE}/api/get/single`);
export const GET_OTHER_USER_SINGLE_SUBNOTE_ENDPOINT = createEndpointHelper("GET", `${BACKEND_BASE_URL}/${SUBNOTE_NAMESPACE}/api/get/from-user`);
export const UPDATE_SUBNOTE_ENDPOINT = createEndpointHelper("PUT", `${BACKEND_BASE_URL}/${SUBNOTE_NAMESPACE}/api/get/update`);

// API Endpoints related to note
const CHAT_NAMESPACE = "post";
export const CREATE_CHAT_ENDPOINT = createEndpointHelper("POST", `${BACKEND_BASE_URL}/${CHAT_NAMESPACE}/api/create`);
export const DELETE_CHAT_ENDPOINT = createEndpointHelper("POST", `${BACKEND_BASE_URL}/${CHAT_NAMESPACE}/api/delete`);
export const GET_ALL_CHATS_ENDPOINT = createEndpointHelper("GET", `${BACKEND_BASE_URL}/${CHAT_NAMESPACE}/api/get-all`);
export const RENAME_CHAT_ENDPOINT = createEndpointHelper("POST", `${BACKEND_BASE_URL}/${CHAT_NAMESPACE}/api/rename`);

// API Endpoints related to conversation labels
const LABEL_NAMESPACE = "labels";
export const CREATE_LABEL_ENDPOINT = createEndpointHelper("POST", `${BACKEND_BASE_URL}/${SUBNOTE_NAMESPACE}/api/create`);
export const DELETE_LABEL_ENDPOINT = createEndpointHelper("DELETE", `${BACKEND_BASE_URL}/${SUBNOTE_NAMESPACE}/api/delete`);
export const GET_ALL_LABELS_ENDPOINT = createEndpointHelper("GET", `${BACKEND_BASE_URL}/${SUBNOTE_NAMESPACE}/api/get/all`);
export const GET_LABEL_BY_ID_ENDPOINT = createEndpointHelper("GET", `${BACKEND_BASE_URL}/${SUBNOTE_NAMESPACE}/api/get/by-id`);
export const GET_LABEL_OF_NOTE_ENDPOINT = createEndpointHelper("GET", `${BACKEND_BASE_URL}/${SUBNOTE_NAMESPACE}/api/get/note`);
export const GET_LABEL_OF_SUBNOTE_ENDPOINT = createEndpointHelper("GET", `${BACKEND_BASE_URL}/${SUBNOTE_NAMESPACE}/api/get/subnote`);
export const SET_NOTE_LABEL_ENDPOINT = createEndpointHelper("PUT", `${BACKEND_BASE_URL}/${SUBNOTE_NAMESPACE}/api/set/note`);
export const SET_SUBNOTE_LABEL_ENDPOINT = createEndpointHelper("PUT", `${BACKEND_BASE_URL}/${SUBNOTE_NAMESPACE}/api/set/subnote`);

// API Endpoints related to user accounts
const ACCOUNTS_NAMESPACE = "accounts";
export const CREATE_ACCOUNT_ENDPOINT = createEndpointHelper("POST", `${BACKEND_BASE_URL}/${ACCOUNTS_NAMESPACE}/api/create`);
export const GET_ACCOUNT_ENDPOINT = createEndpointHelper("GET", `${BACKEND_BASE_URL}/${ACCOUNTS_NAMESPACE}/api/get`);

// API Endpoints related to subscription
const PAYMENTS_NAMESPACE = "payments"
export const CHECK_SUBSCRIPTION_ACTIVE = createEndpointHelper("GET", `${BACKEND_BASE_URL}/${PAYMENTS_NAMESPACE}/api/subscription/active`);
export const GET_SUBSCRIPTION_DATE = createEndpointHelper("GET", `${BACKEND_BASE_URL}/${PAYMENTS_NAMESPACE}/api/subscription/end-date`);
export const GET_ALL_SUBSCRIPTIONS = createEndpointHelper("GET", `${BACKEND_BASE_URL}/${PAYMENTS_NAMESPACE}/api/subscription/get-all`);
export const PAUSE_SUBSCRIPTION = createEndpointHelper("POST", `${BACKEND_BASE_URL}/${PAYMENTS_NAMESPACE}/api/subscription/pause`);
export const CANCEL_SUBSCRIPTION = createEndpointHelper("POST", `${BACKEND_BASE_URL}/${PAYMENTS_NAMESPACE}/api/subscription/cancel`);
export const RESUME_SUBSCRIPTION = createEndpointHelper("POST", `${BACKEND_BASE_URL}/${PAYMENTS_NAMESPACE}/api/subscription/resume`);
