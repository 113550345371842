import * as React from 'react';
import { styled } from '@mui/material/styles';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import CircleIcon from '@mui/icons-material/Circle';

function TodoListContent({ object }) {
  return (
    <div>
      {object.data && object.data[0]["Description"] && (
        <List dense={true}>
          {object.data.map(line =>
            <ListItem key={crypto.randomUUID()}>
              <ListItemIcon sx={{ scale: 0.4 }}>
                <CircleIcon />
              </ListItemIcon>
              <ListItemText
                primary={line["Description"]}
                secondary={null}
              />
            </ListItem>,
          )}
        </List>
      )}
      {object.data && object.data[0]["Description"] == "" && (
        <List dense={true}>
          {object.data.map(line =>
            <ListItem key={crypto.randomUUID()}>
              <ListItemIcon sx={{ scale: 0.4 }}>
                <CircleIcon />
              </ListItemIcon>
              <ListItemText
                primary={line["Name"]}
                secondary={null}
              />
            </ListItem>,
          )}
        </List>
      )}
    </div>
  );
};

export default TodoListContent;
