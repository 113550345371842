// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Conversation Box Styling */
.conversation-box {
  border: 1px solid #dbdbdb;
  border-radius: 6px;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 468px;
  overflow-y: auto;
  padding: 10px;
  background-color: #f9f9f9;
}

.note-conversations {
  padding: 10px;
  overflow-y: auto;
  flex-grow: 1;
}

/* Conversation Styling */
.note-bubble__right {
  background-color: #e6f7ff;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 10px;
  max-width: 80%;
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: pre-wrap; /* Preserve line breaks */
}

/* Format the name differently */
.user-name {
  font-weight: bold;
  color: #007bff;
  margin-bottom: 5px;
}

/* Ensure the text is distinct from the name */
.user-conversation {
  font-size: 14px;
  color: #333;
}
`, "",{"version":3,"sources":["webpack://./src/components/NoteBox.css"],"names":[],"mappings":"AAAA,6BAA6B;AAC7B;EACE,yBAAyB;EACzB,kBAAkB;EAClB,aAAa;EACb,aAAa;EACb,sBAAsB;EACtB,8BAA8B;EAC9B,iBAAiB;EACjB,gBAAgB;EAChB,aAAa;EACb,yBAAyB;AAC3B;;AAEA;EACE,aAAa;EACb,gBAAgB;EAChB,YAAY;AACd;;AAEA,yBAAyB;AACzB;EACE,yBAAyB;EACzB,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,cAAc;EACd,qBAAqB;EACrB,yBAAyB;EACzB,qBAAqB,EAAE,yBAAyB;AAClD;;AAEA,gCAAgC;AAChC;EACE,iBAAiB;EACjB,cAAc;EACd,kBAAkB;AACpB;;AAEA,8CAA8C;AAC9C;EACE,eAAe;EACf,WAAW;AACb","sourcesContent":["/* Conversation Box Styling */\r\n.conversation-box {\r\n  border: 1px solid #dbdbdb;\r\n  border-radius: 6px;\r\n  height: 300px;\r\n  display: flex;\r\n  flex-direction: column;\r\n  justify-content: space-between;\r\n  min-height: 468px;\r\n  overflow-y: auto;\r\n  padding: 10px;\r\n  background-color: #f9f9f9;\r\n}\r\n\r\n.note-conversations {\r\n  padding: 10px;\r\n  overflow-y: auto;\r\n  flex-grow: 1;\r\n}\r\n\r\n/* Conversation Styling */\r\n.note-bubble__right {\r\n  background-color: #e6f7ff;\r\n  padding: 10px;\r\n  margin-bottom: 10px;\r\n  border-radius: 10px;\r\n  max-width: 80%;\r\n  word-wrap: break-word;\r\n  overflow-wrap: break-word;\r\n  white-space: pre-wrap; /* Preserve line breaks */\r\n}\r\n\r\n/* Format the name differently */\r\n.user-name {\r\n  font-weight: bold;\r\n  color: #007bff;\r\n  margin-bottom: 5px;\r\n}\r\n\r\n/* Ensure the text is distinct from the name */\r\n.user-conversation {\r\n  font-size: 14px;\r\n  color: #333;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
