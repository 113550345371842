import React, { useEffect, useRef, useState } from "react";
import $ from "jquery";
import { CookiesProvider, useCookies } from "react-cookie";

import Button from "@mui/material/Button";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

import NoteButtonGroup from "./notes/NoteButtonGroup";

import { getAllNotes, createNewNote } from "../apis/endpoints/Notes";
import { getCredential, isUserAuthenticated } from "../utils/login";

import "./NoteList.css";

function NoteList({
  notes,
  setNotes,
  selectedNote,
  displayedMatches,
  loadNote,
  setSelectedNote,
}) {
  const [cookies, setCookie] = useCookies(["currentUser"]);
  const [backdrop, setBackdrop] = React.useState(false);
  const [newNoteName, setNewNoteName] = useState("");
  const [isInputError, setIsInputError] = useState(false);
  const inputRef = useRef(null);

  const addNote = (newNoteName) => {
    if (isUserAuthenticated(cookies)) {
      // Retrieve credential data for API call
      const credential = getCredential(cookies);

      setBackdrop(true);
      createNewNote(
        credential.id,
        credential.email,
        credential.accessToken,
        newNoteName
      )
        .then((response) => {
          if (response.data != null) {
            const newNoteId = response.data;
            const newNote = {
              id: newNoteId,
              name: newNoteName,
              conversations: null,
            };
            setNotes([newNote, ...notes]);
            setSelectedNote(newNote);
            loadNote(newNote); // Load the new note
          } else {
            // TODO: Handle error when failed to create a note
          }
        })
        .catch((error) => {
          console.error("[NoteList.js] Failed to create new note");
        })
        .finally(() => {
          setBackdrop(false);
        });
    } else {
      // User not authenticated so they cannot create a note => Open modal to prompt
      // login
    }
  };

  const onAddNoteClick = () => {
    if (newNoteName.trim() === "") {
      setIsInputError(false);
      setTimeout(() => {
        setIsInputError(true);
      }, 10); // Short delay to ensure class removal before adding it back
      return;
    }
    // create new note and reset all fields
    addNote(newNoteName);
    setNewNoteName("");
    setIsInputError(false);
  };

  useEffect(() => {
    if (isInputError) {
      const timer = setTimeout(() => {
        setIsInputError(false); // Remove the error state after the animation
      }, 500); // Match the duration of the shake animation

      return () => clearTimeout(timer); // Cleanup the timer if the component unmounts or re-renders
    }
  }, [isInputError]);

  // Handle visual effect when a selected note is switched
  useEffect(() => {
    if (selectedNote != null) {
      // Un-highlight all note items
      $(`.button.note-list-item`).attr("selected", false);

      // Highlight only the selected note item
      $(`.button.note-list-item[id="${selectedNote.id}"]`).attr(
        "selected",
        true
      );
    }
  }, [selectedNote]);

  // want to move most recent note to the top whenever new conversation is sent.
  useEffect(() => {
    console.log(
      "Here is the function for making the recent note to the top of the list not implemented yet"
    );
  }, []);

  return (
    <div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <aside className="menu">
        <p className="menu-label">Notes</p>
        <div className="field">
          <div className="control">
            <input
              ref={inputRef}
              className={`input ${isInputError ? " input-error" : ""}`}
              type="text"
              value={newNoteName}
              onChange={(e) => setNewNoteName(e.target.value)}
              placeholder="New Note Name"
            />
          </div>
        </div>
        <div className="field">
          <div className="control">
            <Button
              variant="contained"
              disabled={isUserAuthenticated(cookies) ? false : true}
              onClick={onAddNoteClick}
            >
              Add Note
            </Button>
          </div>
        </div>
        <div className="scrollable-note-list">
          {notes
            .sort((a, b) => {
              // also how timestamp works is the larger it is the more recent it is so we push to the top
              const dateA = a.updatedAt ? a.updatedAt : 0; // Default to the earliest possible date if updatedAt is missing
              const dateB = b.updatedAt ? b.updatedAt : 0; // testing with order field but will change to "updatedAt" when it's implemented
              return dateB - dateA; // Sort by updatedAt descending
            })
            .map((note) => (
              <NoteButtonGroup
                key={note.id}
                notes={notes}
                setNotes={setNotes}
                note={note}
                loadNote={loadNote}
                selectedNote={selectedNote}

                displayingConversations={displayedMatches}
              />
            ))}
        </div>
      </aside>
    </div>
  );
}

export default NoteList;
