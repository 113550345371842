import {
  CREATE_CHAT_ENDPOINT,
  DELETE_CHAT_ENDPOINT,
  GET_ALL_CHATS_ENDPOINT,
  RENAME_CHAT_ENDPOINT,
} from "../utils/constants.js";

import { makeRequest, generateAuthHeader } from "../utils/utils.js";

/**
 * Call API to  create a new note!
 * @param {string} userId The unique identifier for the user
 * @param {string} email The token used for authentication
 * @param {string} accessToken The email address associated with the user
 * @param {string} noteName The name/title of the new note
 * @return {}
 */
export const createNewNote = async (userId, email, accessToken, noteName) => {
  return makeRequest(
    CREATE_CHAT_ENDPOINT,
    generateAuthHeader(userId, email, accessToken),
    {
      name: noteName,
    }
  );
};

/**
 * Call API to  delete a note & all contents inside the note
 * @param {string} userId The unique identifier for the user
 * @param {string} email The token used for authentication
 * @param {string} accessToken The email address associated with the user
 * @param {string} noteId The unique identifier of the note
 * @return {}
 */
export const deleteNote = async (userId, email, accessToken, noteId) => {
  return makeRequest(
    DELETE_CHAT_ENDPOINT,
    generateAuthHeader(userId, email, accessToken),
    {
      postId: noteId,
    }
  );
};

/**
 * Call API to get all Notes created by a user
 * @param {string} userId The unique identifier for the user
 * @param {string} email The token used for authentication
 * @param {string} accessToken The email address associated with the user
 * @return {}
 */
export const getAllNotes = async (userId, email, accessToken) => {
  return makeRequest(
    GET_ALL_CHATS_ENDPOINT,
    generateAuthHeader(userId, email, accessToken),
    null
  );
};

/**
 * Call API to rename the Note created by a user
 * @param {string} userId The unique identifier for the user
 * @param {string} email The token used for authentication
 * @param {string} accessToken The email address associated with the user
 * @param {string} noteId The unique identifier of the note
 * @param {string} newName The new name for the note
 * @return {}
 */
export const renameNote = async (
  userId,
  email,
  accessToken,
  noteId,
  newName
) => {
  return makeRequest(
    RENAME_CHAT_ENDPOINT,
    generateAuthHeader(userId, email, accessToken),
    {
      postId: noteId,
      newName: newName,
    }
  );
};
