import {
  CHECK_SUBSCRIPTION_ACTIVE,
  GET_SUBSCRIPTION_DATE,
  GET_ALL_SUBSCRIPTIONS,
  RESUME_SUBSCRIPTION,
  PAUSE_SUBSCRIPTION,
  CANCEL_SUBSCRIPTION
} from "../utils/constants.js";

import { makeRequest, generateAuthHeader } from "../utils/utils.js";

/**
 * Call API to check if current premium status is active
 * @param {string} userId The unique identifier for the user
 * @param {string} email The token used for authentication
 * @param {string} accessToken The email address associated with the user
 * @return {}
 */
export const isPremiumActive = async (userId, email, accessToken) => {
  return makeRequest(
    CHECK_SUBSCRIPTION_ACTIVE,
    generateAuthHeader(userId, email, accessToken)
  );
};

/**
 * Call API to get premium end date
 * @param {string} userId The unique identifier for the user
 * @param {string} email The token used for authentication
 * @param {string} accessToken The email address associated with the user
 * @return {}
 */
export const getPremiumEndDate = async (userId, email, accessToken) => {
  return makeRequest(
    GET_SUBSCRIPTION_DATE,
    generateAuthHeader(userId, email, accessToken)
  );
};

/**
 * Call API to check if current payment plan is active
 * @param {string} userId The unique identifier for the user
 * @param {string} email The token used for authentication
 * @param {string} accessToken The email address associated with the user
 * @return {}
 */
export const isPaymentPlanActive = async (userId, email, accessToken) => {
  return makeRequest(
    CHECK_SUBSCRIPTION_ACTIVE,
    generateAuthHeader(userId, email, accessToken)
  );
};

/**
 * Call API to get all subscriptions
 * @param {string} userId The unique identifier for the user
 * @param {string} email The token used for authentication
 * @param {string} accessToken The email address associated with the user
 * @return {}
 */
export const getSubscriptions = async (userId, email, accessToken) => {
  return makeRequest(
    GET_ALL_SUBSCRIPTIONS,
    generateAuthHeader(userId, email, accessToken)
  );
};

/**
 * Call API to resume the current monthly subscription
 * @param {string} userId The unique identifier for the user
 * @param {string} email The token used for authentication
 * @param {string} accessToken The email address associated with the user
 * @param {string} subscriptionId The unique identifier of the subscription
 * @return {}
 */
export const resumeSubscription = async (userId, email, accessToken, subscriptionId) => {
  return makeRequest(
    RESUME_SUBSCRIPTION,
    generateAuthHeader(userId, email, accessToken),
    {
      subscriptionId: subscriptionId
    }
  );
};

/**
 * Call API to pause the current monthly subscription
 * @param {string} userId The unique identifier for the user
 * @param {string} email The token used for authentication
 * @param {string} accessToken The email address associated with the user
 * @param {string} subscriptionId The unique identifier of the subscription
 * @return {}
 */
export const pauseSubscription = async (userId, email, accessToken, subscriptionId) => {
  return makeRequest(
    PAUSE_SUBSCRIPTION,
    generateAuthHeader(userId, email, accessToken),
    {
      subscriptionId: subscriptionId
    }
  );
};

/**
 * Call API to cancel the current monthly subscription
 * @param {string} userId The unique identifier for the user
 * @param {string} email The token used for authentication
 * @param {string} accessToken The email address associated with the user
 * @param {string} subscriptionId The unique identifier of the subscription
 * @return {}
 */
export const cancelSubscription = async (userId, email, accessToken, subscriptionId) => {
  return makeRequest(
    CANCEL_SUBSCRIPTION,
    generateAuthHeader(userId, email, accessToken),
    {
      subscriptionId: subscriptionId
    }
  );
};
