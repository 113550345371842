import { getNoteAudioUrl } from "../apis/endpoints/Conversation";
import axios from "axios";

export const parseConversation = (conversation) => {
  let hasAudio = true;
  if (conversation.audioFileName == null) {
    hasAudio = false;
  }

  return {
    id: conversation.id,
    noteId: conversation.postId,
    text: conversation.content,
    name: conversation.name,
    hasAudio: hasAudio,
    audioName: conversation.audioFileName,
    url: conversation.url ?? null,
  };
};

export const createPendingConversation = (promise) => {
  return {
    id: null,
    noteId: null,
    text: null,
    name: null,
    hasAudio: false,
    audioName: null,
    url: null,
    promise: promise,
  };
};

export const updatePendingConversation = (conversation, responseData) => {
  conversation.id = responseData.id;
  conversation.noteId = responseData.postId;
  conversation.text = responseData.content;
  conversation.name = responseData.name;
  conversation.hasAudio = responseData.audioFileName != null;
  conversation.audioName = responseData.audioFileName;
  conversation.url = responseData.url ?? null;

  delete conversation.promise;

  return conversation;
};

export const downloadAudio = (userId, email, accessToken, conversationId) => {
  return getNoteAudioUrl(
    userId,
    email,
    accessToken,
    conversationId,
    "noteId"
  ).then((response) => {
    if (response.code == 200 && response.data) {
      return fetch(response.data)
        .then((response) => {
          return response.blob();
        })
        .then((blob) => {
          const blobUrl = URL.createObjectURL(blob);
          return blobUrl;
        })
        .catch((error) => {
          console.error(
            "[audioConversation.js] Failed to download the audio file",
            error
          );
        });
    }
    return null;
  });
};
