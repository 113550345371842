import React, { useRef, useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";

import NoteConversationGroup from "../components/NoteConversationGroup";

import Button from '@mui/material/Button';
import ButtonBase from '@mui/material/ButtonBase';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Box from "@mui/material/Box";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import FavoriteIcon from "@mui/icons-material/Favorite";
import NavigationIcon from "@mui/icons-material/Navigation";
import MenuIcon from '@mui/icons-material/Menu';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import CheckIcon from '@mui/icons-material/Check';
import { Link } from "react-router-dom";

import { analytics } from "../App";
import { setUserProperties } from "firebase/analytics";

import { PremiumStatusCtx } from "../contexts";
import { CookiesProvider, useCookies } from "react-cookie";
import {
  GoogleLoginButton,
  LogOutButton,
} from "../components/GoogleLoginComponent/GoogleLoginComponent";
import { isUserAuthenticated, getCredential, logOutUser } from "../utils/login";

function DashboardPage() {
  const navigate = useNavigate();
  const { isPremiumStatusActive, setPremiumStatus } = React.useContext(PremiumStatusCtx);
  const [cookies, setCookie] = useCookies(["currentUser"]);
  const [anchorMenuEl, setAnchorMenuEl] = useState(null);
  const openMenu = Boolean(anchorMenuEl);
  const handleMenuClick = (event) => {
    event.stopPropagation();
    setAnchorMenuEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorMenuEl(null);
  };

  useEffect(() => {
    if (analytics && getCredential(cookies)) {
      setUserProperties(analytics, { user: cookies.currentUser });
    }
  }, [cookies, analytics]);

  return (
    <section className="section">
      <div className="container">
        <div className="LoginSection">
          <div style={{display: getCredential(cookies) ? "block" : "none"}}>
            <Button
              id="basic-button"
              aria-controls={openMenu ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={openMenu ? 'true' : undefined}
              onClick={handleMenuClick}
            >
              <MenuIcon/>
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorMenuEl}
              open={openMenu}
              onClose={handleMenuClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <MenuItem onClick={() => {
                handleMenuClose();
                navigate('/subscription');
              }}>My subscription</MenuItem>
              <MenuItem onClick={() => {
                logOutUser(cookies, setCookie);
                handleMenuClose();
              }}>Logout</MenuItem>
            </Menu>
          </div>
          <div style={{display: getCredential(cookies) ? "none" : "block"}}>
            <GoogleLoginButton/>
          </div>
        </div>
        <h1 className="title has-text-centered">
          IntelliNotes Dashboard
        </h1>
        {/* Steps Cards */}
        {!isUserAuthenticated(cookies) && (
          <div className="columns">
            <div className="column">
              <div className="card" style={{ height: "100%" }}>
                <div className="card-content">
                  <p className="title is-5">Step 1</p>
                  <p className="subtitle is-7">
                    Record your first conversation - it can be anything: your
                    unfiltered thoughts, a list of things to do, places to
                    travel, a list of restaurants you want to try. We’ll
                    transcribe & save it for you to play back.{" "}
                  </p>
                </div>
              </div>
            </div>
            <div className="column">
              <div className="card" style={{ height: "100%" }}>
                <div className="card-content">
                  <p className="title is-5">Step 2</p>
                  <p className="subtitle is-7">Login and/or Sign Up. </p>
                </div>
              </div>
            </div>
            <div className="column">
              <div className="card" style={{ height: "100%" }}>
                <div className="card-content">
                  <p className="title is-5">Step 3</p>
                  <p className="subtitle is-7">
                    Create as many conversations as you want.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
        {/* This section is contains the different Notes, Conversation, and subConversations */}
        <NoteConversationGroup/>

        {isUserAuthenticated(cookies) && !isPremiumStatusActive && <Card sx={{ backgroundColor: '#E0F2F1', padding: '1px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', borderRadius: '8px'}}>
          <CardContent>
            <AlertTitle>Choose Your Plan</AlertTitle>
            <Stack direction="row" spacing={2}>
              <Card>
                <ButtonBase onClick={event => navigate('/subscription')} sx={{ textAlign: 'left' }}>
                  <CardContent>
                    <Typography>
                      One-time Payment
                    </Typography>
                    <Typography sx={{ color: 'text.secondary'}} variant="body2">
                      <CheckIcon/> $30/one-time
                      <br/>
                      <CheckIcon/> Unlimited note-taking
                    </Typography>
                  </CardContent>
                </ButtonBase>
              </Card>
              <Card>
                <ButtonBase onClick={event => navigate('/subscription')} sx={{ textAlign: 'left' }}>
                  <CardContent>
                    <Typography>
                      Monthly Subscription
                    </Typography>
                    <Typography sx={{ color: 'text.secondary'}} variant="body2">
                      <CheckIcon/> $3/month
                      <br />
                      <CheckIcon/> Unlimited note-taking
                    </Typography>
                  </CardContent>
                </ButtonBase>
              </Card>
            </Stack>
          </CardContent>
        </Card>}
      </div>
    </section>
  );
}

export default DashboardPage;
