import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import DashboardPage from "./pages/DashboardPage";
import SubscriptionPage from "./pages/SubscriptionPage";
import "./pages/DashboardPage.css";
import "bootstrap-icons/font/bootstrap-icons.css";

import { getCredential, isUserAuthenticated } from "./utils/login";
import { CookiesProvider, useCookies } from "react-cookie";
import {
  GoogleLogin,
  GoogleOAuthProvider,
  useGoogleLogin,
} from "@react-oauth/google";
import { initializeApp } from "firebase/app";
import { getAnalytics, isSupported } from "firebase/analytics";
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';

import { FilterCtx, PremiumStatusCtx } from "./contexts";

import {
  isPremiumActive
} from "./apis/endpoints/Payments";

const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;

const FIREBASE_CONFIG = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  strorageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

const app = initializeApp(FIREBASE_CONFIG);
let analytics = null;
if ((process.env.REACT_APP_NODE_ENV ?? "development") === "production") {
  isSupported().then((yes) => yes && (analytics = getAnalytics(app)));
}

function App() {
  const theme = createTheme({
    palette: {
      mode: 'light',
      primary: {
        main: '#008080',
      },
      secondary: {
        main: '#FF0000',
      },
    },
  });

  // Create contexts for the app
  const [cookies, setCookie] = useCookies(["currentUser"]);
  const [isFilter, setFilter] = React.useState(false);
  const [isPremiumStatusActive, setPremiumStatus] = React.useState(false);

  React.useEffect(() => {
    if (isUserAuthenticated(cookies)) {
      const cred = getCredential(cookies);
      isPremiumActive(cred.id, cred.email, cred.accessToken).then(result => {
        if (result.code == 200) {
          setPremiumStatus(result.data ?? false);
        }
      }).catch(err => {
        console.error("[App.js] Failed to get premium status", err);
      });
    }
  }, []);

  return (
    <PremiumStatusCtx.Provider value={{ isPremiumStatusActive, setPremiumStatus }}>
      <FilterCtx.Provider value={{ isFilter, setFilter }}>
        <ThemeProvider theme={theme}>
          <GoogleOAuthProvider clientId={CLIENT_ID}>
            <CookiesProvider>
              <Router>
                <Routes>
                  <Route path="/" element={<DashboardPage />} />
                  <Route path="/subscription" element={<SubscriptionPage />} />
                </Routes>
              </Router>
            </CookiesProvider>
          </GoogleOAuthProvider>
        </ThemeProvider>
      </FilterCtx.Provider>
    </PremiumStatusCtx.Provider>
  );
}

export default App;
export { analytics };
